<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <h1>Cartonero Digital SA de CV. &nbsp;&nbsp;➕&nbsp;&nbsp; El Valor que te Entregamos &nbsp;&nbsp;➕&nbsp;&nbsp; Perfil Empresarial.</h1>
    </div>
</div>
<br>
<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
             <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-img1.jpg" alt="blog-details"> -->
                        <div class="date">Actualizado: 14 mayo 2024</div>
                    </div>
                    <div class="article-content">
                    <blockquote class="blockquote">
                        <h5>Los principios que te dan Valor con Cartonero Digital.</h5>
                    </blockquote>
                    <br>
                        <ul class="category" >
                            <!-- <li><a routerLink="/blog">LOS TRES SOCIOS</a></li> -->
                            <li><a routerLink="/blog">VALORES DE CARTONERO DIGITAL</a></li>
                            <li><a routerLink="/blog">MERCADO META</a></li>
                            <li><a routerLink="/blog">SaaS</a></li>
                        </ul>
                        <h4>CARTONERO DIGITAL: Es una Compañía de Tecnología que entrega Soluciones a las Empresas del Sector de Cartón Corrugado.</h4>
                        <p>Respaldamos al Cliente con más de 50 años de experiencia en el mercado Cartonero y de Tecnología.</p>
                        <p>Hemos desarrollado por casi 3 años la única Solución del Mercado para Cotizar Cajas de Cartón en línea.</p>
                        <p>La solución esta disponible por pago de licencias, por usuario, siendo altamente flexible para tu empresa.</p>
                        <br>
                        <blockquote class="blockquote">
                            <h4>Con la infraestructura de Cartonero Digital te entregamos el Cotizador a tu medida y necesidades.</h4>
                        </blockquote>
                        <p>En solo 24 hrs. creamos una "instancia" personalizada a tus necesidades, el cotizador es totalmente Parametrizable.</p>
                        <p>La Solución en Nube nos permite tener la más alta y reciente tecnología de Sistemas, Servivios y Seguridad.</p>
                        <br>
                        <h4>Cartonero Digital impulsa a tu Empresa con el Análisis y Gestión de los Costos asociados con la Fabricación de Cajas de Cartón.</h4>
                        <p>Al utilizar Cartonero Digital, los empresarios tienen certeza en sus costos y ganancias, realizando cotizaciones en minutos.</p>
                        <p>Los cálculos, la analítica de datos de producción, materias primas y costos directos e indirectos asociados con la operación permiten determinar el costo confiable de producción de un pedido.</p>
                        <p>Todos los costos son personalizables en base a tus políticas de costeo, logrando un Cotizador a tu medida.</p>
                    </div>
                </div>
                <!--
                <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a routerLink="/blog-details"><i class="icofont-double-left"></i> Blog Anterior</a>
                    </div>
                    <div class="controls-right">
                        <a routerLink="/blog-details">Siguiente Blog <i class="icofont-double-right"></i></a>
                    </div>
                </div>
                -->
                <!-- 
                <div class="post-comments">
                    <h4>Comentarios</h4>
                    
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖-
                            <img src="assets/img/user1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h3>Ing. Gabriel Dorantes</h3>
                            <p>"Desde la primer semana que usarmos el Cotizador controlamos los Costos, y el margen de ganancia es real"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                           <img src="assets/img/user2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Lic. Eduardo Rodríguez</h4>
                            <p>"Me registré en el Portal, y me invitaron a una Demo en línea y ahora usamos todas las ventajas con Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖
                            <img src="assets/img/user3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Sr. Vicente Mireles</h4>
                            <p>"Por 1 mes con Cartonero Digital seguimos utilizando un Excel complejo, y poco después ya todo es con el Cotizador"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                            <img src="assets/img/user4.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Ing. Juan Antonio Pérez</h4>
                            <p>"Por años buscamos una solución para cotizar Cajas de Cartón, por fin existe una y la mejor Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                
                </div>
                -->
               
                <!--div class="leave-a-reply">
                    <h4>Dejar un comentario, lo publicaremos aquí</h4>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="Su Nombre">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" placeholder="Su mejor correo electrónico">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Su comentario"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-red">Enviar su comentario</button>
                        </div>
                    </div>
                </div-->
                <br>

            </div>
        </div>
    </div>
</div>

<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>