import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Freight } from 'src/app/shared/freight.model'
import { Observable } from 'rxjs';
import { Freightitem } from './freightitem.model';

@Injectable({
  providedIn: 'root'
})
export class FreightService {
  freightList: Freight;
  dataFreight: Freight;
  freight: Freightitem;

  constructor(private http: HttpClient) { }

  getFreightTotalList(){
    return this.http.get(environment.apiURL + '/Freight').toPromise();
  }

  getFreightList(destinyID: number, truckID: number): Observable<Freight[]>{
    return this.http.get<Freight[]>(environment.apiURL + '/Freight/' + destinyID +'/' + truckID);
  };

  putFreightID(id: number, freight: Freightitem) {
    //freight 
    //this.freight.FreightID = Number(this.freightList.FreightID);
    //this.freight.DestinyID = Number(this.freightList.DestinyID);
    //this.freight.TruckID = Number(this.freightList.TruckID);
    //this.freight.FreightCost = Number(this.freightList.FreightCost);
    //var bodyFreight = {
    //  ...this.freight
    //};
    
    return this.http.put(environment.apiURL + '/Freight/' + id, freight);

  };

  postFreight() {
    return this.http.post(environment.apiURL + '/Freight', this.freight);
  };

  deleteFreight(id: number) {
    return this.http.delete(environment.apiURL + '/Freight/' + id).toPromise();
  };

}
