import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getProductList(){
    return this.http.get(environment.apiURL + '/Product').toPromise();
  };

  getProductListId(productID: number){
    return this.http.get(environment.apiURL + '/Product/'+ productID).toPromise();
  };
}
