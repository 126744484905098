<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <h1>El Blog para el Cartonero | Los Expertos de Cartonero Digital te comparten Valor.</h1>
    </div>
</div>
<br>
<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
             <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-img1.jpg" alt="blog-details"> -->
                        <div class="date">Blog Actualizado: 14 mayo 2024</div>
                    </div>
                    <div class="article-content">
                    <blockquote class="blockquote">
                        <h5>Toda la información de Cartoneros para Cartoneros, aquí</h5>
                    </blockquote>
                    <br>
                        Los temas más relevantes del blog Cartonero:
                        <ul class="category" >  
                            <li><a HREF="/Corrugadoras" style="text-decoration:none">CORRUGADORAS</a></li>&nbsp;&nbsp;
                            <li><a HREF="/El_Carton_Corrugado" style="text-decoration:none">ESTÁNDARES</a></li>&nbsp;&nbsp;
                            <!--
                            <li><a routerLink="/blog">COTIZACIÓN EFECTIVA</a></li>&nbsp;&nbsp;
                            <li><a routerLink="/blog">CONOCE A TU CLIENTE</a></li>&nbsp;&nbsp;
                            -->
                            <li><a HREF="/Tecnologia_Software_Como_Servicio_SAAS" style="text-decoration:none">TECNOLOGÍA y SERVICIO SaaS</a></li>
                        </ul>
                        <h4>Conoce los temas más importantes del Mundo del Cartón Corrugado.</h4>
                        <p>Éste Blog se actualiza con frecuencia, regresa y mantente al día. 
                        </p><br>
                        <h4>Estas en el Blog donde reforzarás tus conocimientos de la Industria.</h4>
                        <p>Adicional al Cotizador en línea, el Blog para el Cartonero te entrega toda la información para tu día a día.</p>
                        <p>Consulta, refuerza, aprende y potencializa tu actividad profesional.</p><br>
                        <blockquote class="blockquote">
                            <h4>El Blog del Cartonero con la información de más valor y actualizada del mercado.</h4>
                        </blockquote>
                        <br>
                        <h4>Te acompañamos en tu especialización y crecemos para que te profesionalices cada día.</h4>
                        <p>Los temas estan ordenados por secciones para tu fácil consulta y lectura.</p>
                        <br>
                    </div>
                </div>
                <!--
                <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a routerLink="/blog-details"><i class="icofont-double-left"></i> Blog Anterior</a>
                    </div>
                    <div class="controls-right">
                        <a routerLink="/blog-details">Siguiente Blog <i class="icofont-double-right"></i></a>
                    </div>
                </div>
                <div class="post-comments">
                    <h4>Comentarios</h4>
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖
                -->            <!--<img src="assets/img/user1.jpg" alt="client">-->
                <!--        </div>
                        <div class="comment-content">
                            <h3>Ing. Gabriel Dorantes</h3>
                            <p>"Desde la primer semana que usarmos el Cotizador controlamos los Costos, y el margen de ganancia es real"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                -->            <!--<img src="assets/img/user2.jpg" alt="client">-->
                <!--        </div>
                        <div class="comment-content">
                            <h4>Lic. Eduardo Rodríguez</h4>
                            <p>"Me registré en el Portal, y me invitaron a una Demo en línea y ahora usamos todas las ventajas con Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖
                -->            <!--<img src="assets/img/user3.jpg" alt="client">-->
                <!--        </div>
                        <div class="comment-content">
                            <h4>Sr. Vicente Mireles</h4>
                            <p>"Por 1 mes con Cartonero Digital seguimos utilizando un Excel complejo, y poco después ya todo es con el Cotizador"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                -->            <!--<img src="assets/img/user4.jpg" alt="client">-->
                <!--        </div>
                        <div class="comment-content">
                            <h4>Ing. Juan Antonio Pérez</h4>
                            <p>"Por años buscamos una solución para cotizar Cajas de Cartón, por fin existe una y la mejor Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                </div>
                -->
                <br>
                <!--div class="leave-a-reply">
                    <h4>Dejar un comentario, lo publicaremos aquí</h4>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="Su Nombre">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" placeholder="Su mejor correo electrónico">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Su comentario"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-red">Enviar su comentario</button>
                        </div>
                    </div>
                </div>
                <br-->

            </div>
        </div>
    </div>
</div>

<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>

