import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Workstation } from './workstation.model';


@Injectable({
  providedIn: 'root'
})
export class WorkstationService {
  workstationList: Workstation;
  dataWorkstation: Workstation;

  constructor(private http: HttpClient) { }

  getWorkstationList(){
    return this.http.get(environment.apiURL + '/Workstation').toPromise();
  }

  getWorkstationListId(MachineID: string){
    return this.http.get(environment.apiURL + '/Workstation/'+ MachineID).toPromise();
  }

  putWorkstationID(id: string, workstation: Workstation) {
    return this.http.put(environment.apiURL + '/Workstation/' + id, workstation);
  };

  postWorkstation() {
    return this.http.post(environment.apiURL + '/Workstation', this.workstationList);
  };

  deleteWorkstation(id: number) {
    return this.http.delete(environment.apiURL + '/Workstation/' + id).toPromise();
  };
}
