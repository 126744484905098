import { Component, OnInit } from '@angular/core';
import { QuoteService } from 'src/app/shared/quote.service';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuoteItemsComponent } from '../quote-items/quote-items.component';
import { QuoteSheetComponent } from '../quote-items/quote-sheet.component';
import { CustomerService } from 'src/app/shared/customer.service';
import { Customer } from 'src/app/shared/customer.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Destiny } from 'src/app/shared/destiny.model';
import { DestinyService } from 'src/app/shared/destiny.service';
import { InterestrateService } from 'src/app/shared/interestrate.service';
import { Interestrate } from 'src/app/shared/interestrate.model';
import { TruckType } from 'src/app/shared/TruckType.model';
import { TruckTypeService } from 'src/app/shared/TruckType.service';
import { Freight } from 'src/app/shared/freight.model';
import { FreightService } from 'src/app/shared/freight.service';
import { Capacitytruck } from 'src/app/shared/capacitytruck.model';
import { CapacitytruckService } from 'src/app/shared/capacitytruck.service';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';
import { WorkstationService } from 'src/app/shared/workstation.service';
import { Workstation } from 'src/app/shared/workstation.model';
import { Product } from 'src/app/shared/product.model';
import { ProductService } from 'src/app/shared/product.service';
import { CostrateService } from 'src/app/shared/costrate.service';
import { Costrate } from 'src/app/shared/costrate.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { InkService } from 'src/app/shared/ink.service';
import { Ink } from 'src/app/shared/ink.model';
import { exit } from 'process';
 

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styles: []
})
export class QuoteComponent implements OnInit {
  customerList : Customer [];
  destinyList: Destiny [];
  truckTypeList: TruckType [];
  freightList: Freight [];
  capacitytruckList: Capacitytruck [];
  capacityTruck: number;
  interestrateList: Interestrate [];
  boardList: Board [];
  boardCost: Boardcost;
  workstationList: Workstation [];
  productList: Product [];
  costrateList: Costrate [];
  auxiliaryList: Auxiliary [];
  inkList: Ink [];
  isValid: boolean = true;
  averageOrder: number;
  isBoxOrSheet: boolean = true;
  dateQuote: Date = new Date();

  
  constructor(public service: QuoteService, private dialog: MatDialog,
     private customerService: CustomerService, private destinyService: DestinyService,
     private trucktypeService: TruckTypeService, private freightService: FreightService,
     private capacitytruckService: CapacitytruckService, private workstationService: WorkstationService,
     private boardService: BoardService, private auxiliaryService: AuxiliaryService,
     private boardcostService: BoardcostService, private inkService: InkService, 
     private interestrateService: InterestrateService, private toastr: ToastrService, 
     private productService: ProductService, private costrateService: CostrateService,
     private router: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let quoteID = this.currentRoute.snapshot.paramMap.get('id');
    if(quoteID == null)
      this.resetForm();
    else {
      this.service.getQuoteByID(parseInt(quoteID)).then(res => {
        this.service.formData = res.quote;
        this.service.quoteItems = res.quoteDetails;
        this.boardcostService.getBoardCostListId(this.service.quoteItems[0].BoardID).subscribe((boardCosts: Boardcost) => {
          this.boardCost = boardCosts, this.service.formData.MonthlyOrderTon = parseFloat((this.service.formData.MonthlyOrder * this.service.quoteItems[0].BoxWeight / 1000000).toFixed(2)), this.onContactName(); }, error => console.error(error));
      });
    };
    this.customerService.getCustomerList().then(res => this.customerList = res as Customer[]);
    this.destinyService.getDestinyList().then(res => this.destinyList = res as Destiny[]);
    this.trucktypeService.getTruckTypeList().then(res => this.truckTypeList = res as TruckType[]);
    this.interestrateService.getInterestRateList().then(res => this.interestrateList = res as Interestrate[]);
    this.boardService.getBoardList().then(res => this.boardList = res as Board[]);
    this.auxiliaryService.getAuxiliaryList().then(res => this.auxiliaryList = res as Auxiliary[]);
    this.inkService.getInkList().then(res => this.inkList = res as Ink[]);
    this.workstationService.getWorkstationList().then(res => this.workstationList = res as Workstation[]);
    this.productService.getProductList().then(res => this.productList = res as Product[]);
    this.costrateService.getCostRateList().then(res => this.costrateList = res as Costrate[]);

    this.freightService.getFreightList(1, 1).subscribe((freights: Freight[]) => {
      this.freightList = freights}, error => console.error(error));

    this.capacitytruckService.getCapacityTruckList(1, 1, 1).subscribe((capacitytrucks: Capacitytruck[]) => {
      this.capacitytruckList = capacitytrucks}, error => console.error(error));
    
      this.onContactName();
      
    this.onQuoteBoxOrSheet();
  };

  resetForm(form?: NgForm){
    if (form = null)
    form.resetForm();
    this.service.formData = {
      QuoteID: null,
      QuoteNumber: Math.floor(1000000 + Math.random()*9000000).toString(),
      QuoteDate: this.dateQuote,
      CustomerID: 0,
      CustomerSKU: '',
      DestinyID: 0,
      TruckID: 0,
      RateID: 3,
      AverageOrder: 10000,
      MonthlyOrder : 10000,
      BoxShipment : 10000,
      FreightCost: 0,
      SaleCommission: 0,
      FinancialExpense: 0,
      ManufacturingCost: 0,
      TotalCost: 0,
      CostMBox: 0,
      CostMM2: 0,
      CostTon: 0,
      TargetProfit: 0,
      PriceWithoutFreight: 0,
      PriceWithFreight: 0,
      ProfitWithoutFreight: 0,
      ProfitWithFreight: 0,
      PriceMBox : 0,
      PriceMM2 : 0,
      PriceTon : 0,
      DeletedQuoteItemIDs:'',
      State: 0,
      TruckName: '',
      Rate: 0,
      ContactName: '',
      MonthlyOrderTon : 0,
    }
    this.service.quoteItems=[];
    
    //this.onQuoteBoxOrSheet();
  };

  AddOrEditQuoteItem(quoteItemIndex, QuoteID){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height="95%";
    dialogConfig.data = { quoteItemIndex, QuoteID }
    //test
    if (this.service.quoteItems.length == 0){
      this.dialog.open(QuoteItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
        this.updateGrandTotal(); this.updateTotalCost(); this.averageOrder = this.service.formData.AverageOrder;
      });
    } else {
      if (this.isBoxOrSheet == true && this.service.quoteItems[0].StyleID != 6){
        this.dialog.open(QuoteItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
          this.updateGrandTotal(); this.updateTotalCost(); this.averageOrder = this.service.formData.AverageOrder;
        });
      } else {
        dialogConfig.height="65%";
        this.dialog.open(QuoteSheetComponent, dialogConfig).afterClosed().subscribe(res =>{
          this.updateGrandTotal(); this.updateTotalCost(); this.averageOrder = this.service.formData.AverageOrder;
        });
      };
    };
  };

  AddOrEditQuoteSheet(quoteItemIndex, QuoteID){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height="65%";
    dialogConfig.data = { quoteItemIndex, QuoteID }
    this.dialog.open(QuoteSheetComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.updateGrandTotal(); this.updateTotalCost(); this.averageOrder = this.service.formData.AverageOrder;
    });
    this.isBoxOrSheet = false;
  };

  onDeleteQuoteItem(quoteItemID: number, i: number){
    if(quoteItemID != null)
    this.service.formData.DeletedQuoteItemIDs += quoteItemID + ",";
    this.service.quoteItems.splice(i,1);
    this.updateGrandTotal();
  };

  updateGrandTotal(){
    this.service.formData.ManufacturingCost = this.service.quoteItems.reduce((prev, curr) => {
      return prev + curr.Total;
    }, 0);
    
    this.service.formData.ManufacturingCost = parseFloat(this.service.formData.ManufacturingCost.toFixed(2));
  };

  validateForm(){
    this.isValid = true;
    if (this.service.formData.CustomerID == 0)
      this.isValid=false;
    else if (this.service.quoteItems.length == 0)
      this.isValid=false;
    return this.isValid;
  };

  onsaveQuote(){
    if (this.validateForm()){
      if(this.service.quoteItems[0].PlateDie == false){
        this.service.quoteItems[0].CostDie = 0;
        this.service.quoteItems[0].CostPlate = 0;
      }
      if(this.service.formData.CustomerSKU == ""){
        this.service.formData.CustomerSKU = this.service.quoteItems[0].StyleName;
      }
      this.service.saveOrUpdateQuote().subscribe(res => {
        this.resetForm();
        this.toastr.success('Grabado Exitosamente!', 'Cartonero Digital.');
        this.router.navigate(['./quotes']);
      });
    };
  };

  onSubmit(form: NgForm){
    if(this.capacityTruck < this.service.quoteItems[0].AreaShipment){
      if (confirm('Te recomendamos seleccionar un transporte de mayor capacidad, el actual no es suficiente. Si decides continuar el costo del flete se elevará._' + ' Estas seguro de Guardar la cotización?') == true){
        this.onsaveQuote();
      } else {
        this.toastr.info('Te recomendamos seleccionar un transporte de mayor capacidad, el actual no es suficiente.', 'Cartonero Digital.');
        exit();
      };
    }else {
      this.onsaveQuote();
    };
  };

  updateState(ctrl){
    if (ctrl.selectedIndex == 0) {
      this.service.formData.State = 0;
    } 
    else {
      this.service.formData.State = this.destinyList[ctrl.selectedIndex-1].StateID;
      this.onFreightList();
    }
  }

  updateFreight(ctrl){
    if (ctrl.selectedIndex == 0) {
      this.service.formData.FreightCost = 0;
    } 
    else {
          this.onFreightList();
    };
  };

  onFreightList(){
    if(this.service.formData.TruckID != 0)
    this.freightService.getFreightList(+this.service.formData.DestinyID, +this.service.formData.TruckID).subscribe((freights: Freight[]) => {
      this.freightList = freights; this.onFreight(); this.onGetCapacityTruck()}, error => console.error(error));
      ;
  };

  onFreight(){
    this.service.formData.FreightCost = this.freightList[0].FreightCost;
  };

  onGetCapacityTruck(){
    if (this.service.quoteItems.length >= 1)
      this.capacitytruckService.getCapacityTruckList(+this.service.formData.TruckID, +this.service.quoteItems[0].FluteID, 1).subscribe(capacitytrucks => {
        this.capacitytruckList = capacitytrucks; 
        this.onCapacityTruck(); 
        this.updateTotalCost();
      });
  };

  onCapacityTruck(){
    this.capacityTruck = this.capacitytruckList[0].CapacityMM2;
  };

  updateTotalCost(){
    let interestRate: number = 0;
    this.onCapacityTruck();
    interestRate = this.interestrateList.find(interestrate => interestrate.RateID == this.service.formData.RateID).Rate;
    this.service.formData.FinancialExpense = parseFloat((interestRate * this.service.formData.PriceMBox * this.service.formData.AverageOrder / 100000).toFixed(4));
    this.service.formData.SaleCommission = parseFloat((this.service.formData.PriceMBox * this.service.formData.AverageOrder / 100000).toFixed(4));
    this.service.formData.TotalCost = parseFloat((this.service.formData.ManufacturingCost + (Math.ceil(this.service.quoteItems[0].AreaShipment / this.capacityTruck) * this.service.formData.FreightCost) + this.service.formData.SaleCommission + this.service.formData.FinancialExpense).toFixed(4));
    this.service.formData.CostMBox = parseFloat((this.service.formData.TotalCost * 1000 / this.service.formData.AverageOrder).toFixed(4));
    this.service.formData.CostMM2 = parseFloat((this.service.formData.TotalCost * 1000 / (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder)).toFixed(4));
    this.service.formData.CostTon = parseFloat((this.service.formData.TotalCost / (this.service.quoteItems[0].TotalWeightRequired * this.service.formData.AverageOrder / 1000000)).toFixed(4));
    this.service.formData.PriceMM2 = parseFloat((this.service.formData.PriceMBox / this.service.quoteItems[0].TotalAreaRequired).toFixed(4));
    this.service.formData.PriceTon = parseFloat((this.service.formData.PriceMBox * 1000 / this.service.quoteItems[0].TotalWeightRequired).toFixed(4));
    this.service.formData.PriceWithoutFreight = parseFloat(((this.service.formData.CostMBox - (this.service.formData.FreightCost * 1000 / this.service.formData.AverageOrder)) / (1-(this.service.formData.TargetProfit / 100))).toFixed(4)); 
    this.service.formData.PriceWithFreight = parseFloat((this.service.formData.CostMBox  / (1-(this.service.formData.TargetProfit / 100))).toFixed(4)); 
    this.service.formData.ProfitWithoutFreight = parseFloat(((this.service.formData.PriceMBox - (this.service.formData.CostMBox - (this.service.formData.FreightCost * 1000 / this.service.formData.AverageOrder))) * 100 / this.service.formData.PriceMBox).toFixed(2));
    this.service.formData.ProfitWithFreight = parseFloat(((this.service.formData.PriceMBox - this.service.formData.CostMBox) * 100 / this.service.formData.PriceMBox).toFixed(2));
    this.service.formData.MonthlyOrderTon = parseFloat((this.service.formData.MonthlyOrder * this.service.quoteItems[0].BoxWeight / 1000000).toFixed(2));
  };

  updateTotalCostFreight(){
    this.updateTotalCost()
    this.service.formData.PriceMBox = 0;
  };

  updateQuoteItem(){
    
    if(this.service.formData.AverageOrder <= 0 || this.service.formData.AverageOrder == null){
      this.service.formData.AverageOrder = 1;
      this.service.formData.MonthlyOrderTon = 0;
    };
    if(this.service.formData.MonthlyOrder <= 0 || this.service.formData.MonthlyOrder == null){
      this.service.formData.MonthlyOrder = 1;
      this.service.formData.MonthlyOrderTon = 0;
    };
    if (this.service.quoteItems.length >= 1){
      this.onBoardCost();
      this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(4));
      this.service.formData.ManufacturingCost = this.service.quoteItems[0].Total;
      this.updateTotalCost();
    }
  };

  updateAreaShipment(){
    if(this.service.formData.BoxShipment <= 0 || this.service.formData.BoxShipment == null){
      this.service.formData.BoxShipment = 1;
    }
    if (this.service.quoteItems.length >= 1){
      this.service.quoteItems[0].AreaShipment = parseFloat((this.service.quoteItems[0].TotalAreaRequired * this.service.formData.BoxShipment / 1000).toFixed(4));
      this.updateTotalCost();
    };
  };

  onGetCustomerProfit(ctrl){
    if (ctrl.selectedIndex == 0) {
      this.service.formData.TargetProfit = 0;
    } 
    else {
      this.service.formData.TargetProfit = this.customerList[ctrl.selectedIndex-1].TargetProfitability;
      this.onContactName();
    };
  };

  onBoardCost(){
    let productionTimeCorr: number; let productionTimeConv: number; 
    let productionTimeFolding: number; let productionTimePartition: number;
    let costMacFolding: number; let costMacPartition: number = 0;
    let costInk01: number = 0; let costInk02: number = 0; let costInk03: number = 0; let costInk04: number = 0; 
    let costInk05: number = 0; let costInk06: number = 0; let inkCostTotalArea: number = 0;
    let costPVA01: number = 0; let costCover01: number = 0; let indexSelected: number;
    let costStrapping01: number = 0; let costStrapping02: number = 0; 
    let thickness: number = 0; let costPlateCM2: number = 0; let costPlate: number = 0;
    let costDieCM: number = 0; let costDie: number = 0; let boxArea: number = 0;
    let workstation: number; let workstationPartition: number = 4; let workstationFolding: number = 3;
    let plateRatCon: number = 0; let costTape: number = 0;
    let costFilm: number = 0; let costBoardPackaging: number = 0;
    let costPallet: number = 0; let smOrder: number;

    smOrder = this.service.formData.AverageOrder * this.service.quoteItems[0].TotalAreaRequired
    
    //in this block, we are going to calculated the production Time for corrugator
    indexSelected = this.boardList.findIndex(typeDensity => typeDensity.BoardID == this.service.quoteItems[0].BoardID);    
    if (this.boardList[indexSelected].TypeDensity == 0) {
      productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenLow * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
    } else if(this.boardList[indexSelected].TypeDensity == 1) {
      productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenMed * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
    } else {
      productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenHig * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
    };
    //in this block, we are going to calculated the production Time for converting
    //for now, we use the machine 2-IMP003, but next we should determine the manufacturing route.
    if (this.service.quoteItems[0].StyleID == 1){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 2){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 3){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 4){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 5){
      workstation = 2;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    } else if (this.service.quoteItems[0].StyleID == 6){
      workstation = 0;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    } else if(this.service.quoteItems[0].StyleID == 7){
      workstation = 2;
      workstationPartition = 4;
      boxArea = (this.service.quoteItems[0].LengthBoard * this.service.quoteItems[0].WidthBoard / 1000000);
    } else if (this.service.quoteItems[0].StyleID == 8){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    } else if (this.service.quoteItems[0].StyleID == 9){
      workstation = 1;
      boxArea = this.service.quoteItems[0].BoxArea;
    };
    //call board cost
    this.boardcostService.getBoardCostListId(this.service.quoteItems[0].BoardID).subscribe((boardCosts: Boardcost) => {
      this.boardCost = boardCosts}, error => console.error(error));
    //if (this.boardCost.BoardID != null){
    if (this.service.quoteItems[0].StyleID <= 5 || this.service.quoteItems[0].StyleID >= 7){
      if (this.boardList[indexSelected].TypeDensity == 0 && this.service.quoteItems[0].Register =='Bajo'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 0 && this.service.quoteItems[0].Register =='Medio'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 0 && this.service.quoteItems[0].Register =='Alto'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegHigDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if (this.boardList[indexSelected].TypeDensity == 1 && this.service.quoteItems[0].Register =='Bajo' ){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 1 && this.service.quoteItems[0].Register =='Medio'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 1 && this.service.quoteItems[0].Register =='Alto'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if (this.boardList[indexSelected].TypeDensity == 2 && this.service.quoteItems[0].Register =='Bajo' ){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 2 && this.service.quoteItems[0].Register =='Medio'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 2 && this.service.quoteItems[0].Register =='Alto'){
        productionTimeConv = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegHigDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      };
      productionTimeCorr = productionTimeCorr + 1;
      productionTimeConv = productionTimeConv + 10;
      //this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
      //this.service.quoteItems[0].ProductionTimeConv = productionTimeConv;

      //in this block, we will calculate production cost
      this.service.quoteItems[0].CostMP = parseFloat((this.boardCost.BoardPaperCostMM2 * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100)) * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4));
      this.service.quoteItems[0].CostMPNeto = this.service.quoteItems[0].CostMP;
      //time and costo if the product is a box
      if (smOrder <= 1000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr01;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv01;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste01 / 100));
      } else if(smOrder <= 2000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr02;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv02;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste02 / 100));
      } else if(smOrder <= 3000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr03;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv03;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste03 / 100));
      } else if(smOrder <= 4000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr04;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv04;
        this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste04 / 100));
      };
      //this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
      //this.service.quoteItems[0].ProductionTimeConv = productionTimeConv + productionTimePartition;
      //The CostAuxCorrM2 is the cost by production sheetboard and sales sheetboard.
      this.service.quoteItems[0].CostAuxCorr = parseFloat(((this.boardCost.StarchCostMM2 + +this.boardCost.CoatingCostMM2 + +this.boardCost.OtherAuxCostMM2) * (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000)).toFixed(4));        
      this.service.quoteItems[0].CostCorrToConv = parseFloat(((this.costrateList[0].DirLaborCostHR + +this.costrateList[0].IndLaborCostHR + +this.costrateList[0].MaintenanceCostHR + +this.costrateList[0].EnergyCostoHR + +this.costrateList[0].WaterCostHR + +this.costrateList[0].GasCostHR + +this.costrateList[0].IndManCostHR) * (productionTimeCorr / 60)).toFixed(4));
      this.service.quoteItems[0].CostFixed = parseFloat((this.costrateList[0].CorpMiscCostHR * productionTimeCorr / 60).toFixed(4));
      if(this.service.quoteItems[0].StyleID == 7){
        if(this.service.quoteItems[0].InkNumber == 0){
          if (this.service.quoteItems[0].DieType == "Plano desplegado" || this.service.quoteItems[0].DieType == "Plano desplegado complejo"){
            costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
            costMacFolding = 0;
            productionTimeFolding = 0;
            productionTimeConv = 30;
            this.service.quoteItems[0].CostMacConverting = costMacPartition;
          } else {
            productionTimeConv = 30;
            costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
            costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
            this.service.quoteItems[0].CostMacConverting = costMacPartition + costMacFolding;
          };
        } else {
            if (this.service.quoteItems[0].DieType == "Plano desplegado" || this.service.quoteItems[0].DieType == "Plano desplegado complejo"){
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = 0;
              productionTimeFolding = 0;
              this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition;        
            } else {
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
              this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition + costMacFolding;        
            };
        };
      } else if (this.service.quoteItems[0].StyleID == 5){
          if (this.service.quoteItems[0].DieType == "Curvo pegado"){
            costMacPartition = 0;
            productionTimePartition = 0;
            costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
            this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacFolding;        
          } else {
            costMacPartition = 0;
            costMacFolding = 0;
            productionTimePartition = 0;
            productionTimeFolding = 0;
            this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4));        
          };   
      } else {
        costMacPartition = 0;
        costMacFolding = 0;
        productionTimePartition = 0;
        productionTimeFolding = 0;
        this.service.quoteItems[0].CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4));        
      };
      //productionTimeConv = productionTimeConv;
      this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
      this.service.quoteItems[0].ProductionTimeConv = productionTimeConv + productionTimePartition + productionTimeFolding;
      //in this block we are going to calculated the inks cost for each colors.
      if (this.service.quoteItems[0].InkNumber == 1){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 == 0;
        costInk03 == 0;
        costInk04 == 0;
        costInk05 == 0;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk02 == 0;
        this.service.quoteItems[0].PerInk03 == 0;
        this.service.quoteItems[0].PerInk04 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate02 == 0;
        this.service.quoteItems[0].PerPrintingPlate03 == 0;
        this.service.quoteItems[0].PerPrintingPlate04 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 2){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 == 0;
        costInk04 == 0;
        costInk05 == 0;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk03 == 0;
        this.service.quoteItems[0].PerInk04 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate03 == 0;
        this.service.quoteItems[0].PerPrintingPlate04 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 3){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 === 0;
        costInk05 === 0;
        costInk06 === 0;
        this.service.quoteItems[0].PerInk04 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate04 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 4){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.service.quoteItems[0].InkID04).Cost;
        costInk05 == 0;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk05 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate05 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 5){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.service.quoteItems[0].InkID04).Cost;
        costInk05 = this.inkList.find(ink05 => ink05.InkID == this.service.quoteItems[0].InkID05).Cost;
        costInk06 == 0;
        this.service.quoteItems[0].PerInk06 == 0;
        this.service.quoteItems[0].PerPrintingPlate06 == 0;
      } else if(this.service.quoteItems[0].InkNumber == 6){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.service.quoteItems[0].InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.service.quoteItems[0].InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.service.quoteItems[0].InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.service.quoteItems[0].InkID04).Cost;
        costInk05 = this.inkList.find(ink05 => ink05.InkID == this.service.quoteItems[0].InkID05).Cost;
        costInk06 = this.inkList.find(ink06 => ink06.InkID == this.service.quoteItems[0].InkID06).Cost;
      };
      //The number 2 represent the consumption per m2 (2gr/m2), and 1.1 is the waste factor, 10% over the consumption.
      inkCostTotalArea = 2 * this.service.quoteItems[0].BoxArea * this.service.formData.AverageOrder * 1.1 / 1000;//total consumption Kg / by order.
      this.service.quoteItems[0].CostInk = parseFloat(((costInk01 * ((this.service.quoteItems[0].PerInk01 * inkCostTotalArea / 100) + 3)) + (costInk02 * ((this.service.quoteItems[0].PerInk02 * inkCostTotalArea / 100) + 3))  + (costInk03 * ((this.service.quoteItems[0].PerInk03 * inkCostTotalArea / 100) + 3))  + (costInk04 * ((this.service.quoteItems[0].PerInk04 * inkCostTotalArea / 100) + 3))  + (costInk05 * ((this.service.quoteItems[0].PerInk05 * inkCostTotalArea / 100) + 3))  + (costInk06 * ((this.service.quoteItems[0].PerInk06 * inkCostTotalArea / 100) + 3))).toFixed(4));
      //in this block we are going to calculated the cost plate and Die Cuter
      //Plate Cost / cm2 and Die Cost / cm
      costPlateCM2 = (this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatCost) * (1 + ((this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatWaste)/100));
      //Total Plate Cost, all color and total Die
      costPlate = parseFloat((((+this.service.quoteItems[0].PerPrintingPlate01 + +this.service.quoteItems[0].PerPrintingPlate02 + +this.service.quoteItems[0].PerPrintingPlate03 + +this.service.quoteItems[0].PerPrintingPlate04 + +this.service.quoteItems[0].PerPrintingPlate05 + +this.service.quoteItems[0].PerPrintingPlate06)/100) * ((this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100) * costPlateCM2) / (this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxLifeTime)).toFixed(4))
      this.service.quoteItems[0].CostPlateTotal = parseFloat((((+this.service.quoteItems[0].PerPrintingPlate01 + +this.service.quoteItems[0].PerPrintingPlate02 + +this.service.quoteItems[0].PerPrintingPlate03 + +this.service.quoteItems[0].PerPrintingPlate04 + +this.service.quoteItems[0].PerPrintingPlate05 + +this.service.quoteItems[0].PerPrintingPlate06)/100) * ((this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].LengthBoard / 100 ) * costPlateCM2)).toFixed(4))
      //section costDie
      if(this.service.quoteItems[0].StyleID == 5){
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatWaste)/100));
        costDie = parseFloat((((2 * this.service.quoteItems[0].Length / 10) + (2 * this.service.quoteItems[0].Width / 10)) * (this.service.quoteItems[0].Output * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxLifeTime)).toFixed(4))
        this.service.quoteItems[0].CostDieTotal = parseFloat((((2 * this.service.quoteItems[0].Length / 10) + (2 * this.service.quoteItems[0].Width / 10)) * (this.service.quoteItems[0].Output * costDieCM)).toFixed(4))
        if (this.service.quoteItems[0].DieType =="Curvo desplegado complejo"){
          costDie = costDie * 1.65;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.65;
        } else if (this.service.quoteItems[0].DieType =="Curvo pegado"){
          costDie = costDie * 1.25;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.25;
        }
      } else if (this.service.quoteItems[0].StyleID == 7){
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 25).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 25).AuxMatWaste)/100));
        costDie = parseFloat((((2 * this.service.quoteItems[0].Length / 10) + (2 * this.service.quoteItems[0].Width / 10)) * (this.service.quoteItems[0].Output * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 25).AuxLifeTime)).toFixed(4))
        this.service.quoteItems[0].CostDieTotal = parseFloat((((2 * this.service.quoteItems[0].Length / 10) + (2 * this.service.quoteItems[0].Width / 10)) * (this.service.quoteItems[0].Output * costDieCM)).toFixed(4))
        if(this.service.quoteItems[0].DieType == "Plano desplegado complejo"){
          costDie = costDie * 1.50;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.65;
        } else if (this.service.quoteItems[0].DieType == "Plano pegado"){
          costDie = costDie * 1.20;
          this.service.quoteItems[0].CostDieTotal = this.service.quoteItems[0].CostDieTotal * 1.25;
        }
      } else {
        costDieCM = 0;
        costDie = 0;
      };
      //Total Plate Cost per order
      plateRatCon = this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatRatCon;
      if (plateRatCon <= this.service.formData.MonthlyOrder){
        this.service.quoteItems[0].CostPlate = (costPlate / plateRatCon) * this.service.formData.AverageOrder;
        this.service.quoteItems[0].CostDie = (costDie / plateRatCon) * this.service.formData.AverageOrder;
      } else {
        this.service.quoteItems[0].CostPlate = (costPlate / this.service.formData.MonthlyOrder) * this.service.formData.AverageOrder;
        this.service.quoteItems[0].CostDie = (costDie / this.service.formData.MonthlyOrder) * this.service.formData.AverageOrder;
      };
      //in this block we are going to calculated the PVA cost and rate consuptiom.
      if (this.service.quoteItems[0].ClosureID > 1){
        costPVA01 = this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatCost * this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatRatCon * (1 + (this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatWaste / 100));
        this.service.quoteItems[0].CostPVA = parseFloat((costPVA01 * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4));
      }else {
        this.service.quoteItems[0].CostPVA = 0;
      };
      //in this block, we are going to calculated the Strech Film cost.
      if (this.service.quoteItems[0].StrechFilm == true){
        costFilm = this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatCost * this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatRatCon * (1 + (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatWaste / 100));
        this.service.quoteItems[0].CostFilm = parseFloat((costFilm * (this.service.formData.AverageOrder /(this.service.quoteItems[0].BoxBundle * (this.service.quoteItems[0].BundleWidthPallet + this.service.quoteItems[0].BundleAlongPallet) * this.service.quoteItems[0].Layer))).toFixed(4));
      }else {
        this.service.quoteItems[0].CostFilm = 0;
      };

      //in this block, we are gonna calculated the Board for Packaging cost
      costBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatCost * this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatRatCon * (1 + (this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatWaste / 100));
      this.service.quoteItems[0].CostBoardPackaging = parseFloat((costBoardPackaging * (this.service.formData.AverageOrder /(this.service.quoteItems[0].BoxBundle * (this.service.quoteItems[0].BundleWidthPallet + this.service.quoteItems[0].BundleAlongPallet) * this.service.quoteItems[0].Layer))).toFixed(4));
      //in this block, we are gonna calculated the Pallet cost
      if (this.service.quoteItems[0].Pallet == true){
        costPallet = this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatCost * this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatRatCon * (1 + (this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatWaste / 100));
        this.service.quoteItems[0].CostPallet = parseFloat((costPallet * (this.service.formData.AverageOrder /(this.service.quoteItems[0].BoxBundle * (this.service.quoteItems[0].BundleWidthPallet + this.service.quoteItems[0].BundleAlongPallet) * this.service.quoteItems[0].Layer))).toFixed(4));
      }else {
        this.service.quoteItems[0].CostPallet = 0;
      };
      
      //in this block we are calculated the Tape
      if (this.service.quoteItems[0].TapeID != 1){
        costTape = this.auxiliaryList.find(tape => tape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatCost * (1 + (this.auxiliaryList.find(tape => tape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatWaste / 100));
        if ((this.service.quoteItems[0].StyleID <= 4) || (this.service.quoteItems[0].StyleID >= 8)){
          this.service.quoteItems[0].CostTape = parseFloat((costTape * this.service.quoteItems[0].LengthBoard * this.service.formData.AverageOrder * this.service.quoteItems[0].TapeQuantity / 1000).toFixed(4));
        } else if((this.service.quoteItems[0].StyleID == 5) || (this.service.quoteItems[0].StyleID == 7)){
          this.service.quoteItems[0].CostTape = parseFloat((costTape * this.service.quoteItems[0].LengthBoard * (this.service.formData.AverageOrder / this.service.quoteItems[0].Output) * this.service.quoteItems[0].TapeQuantity / 1000).toFixed(4));
        }
      }else{
        this.service.quoteItems[0].CostTape = 0;
        this.service.quoteItems[0].TapeQuantity = 0;
      };

      //in this block we are going to calculated the Cover.
      costCover01 = this.auxiliaryList.find(Cover01 => Cover01.AuxMatID == 9).AuxMatCost
      if ((this.service.quoteItems[0].StyleID <= 4) || (this.service.quoteItems[0].StyleID == 6) || (this.service.quoteItems[0].StyleID >= 8)){
        this.service.quoteItems[0].CostCover = 0;
      } else {
        this.service.quoteItems[0].CostCover = parseFloat((costCover01 * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4)); 
      };
      costStrapping01 = this.auxiliaryList.find(Strapping01 => Strapping01.AuxMatID == 1).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping01 => Strapping01.AuxMatID == 1).AuxMatWaste / 100))
      thickness = this.boardList.find(thicknes => thicknes.BoardID == this.service.quoteItems[0].BoardID).Thickness;
      this.service.quoteItems[0].CostStrapping5mm = parseFloat((costStrapping01 * (((2 * this.service.quoteItems[0].WidthBoard * this.service.quoteItems[0].StrapNumber) + (4 * thickness * this.service.quoteItems[0].BoxBundle * this.service.quoteItems[0].StrapNumber)) * (this.service.formData.AverageOrder/this.service.quoteItems[0].BoxBundle)) / 1000000).toFixed(4));        
      costStrapping02 = this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatWaste / 100))
      this.service.quoteItems[0].CostStrapping12mm = parseFloat((costStrapping02 * Math.round(((this.service.formData.AverageOrder / this.service.quoteItems[0].BoxBundle) / ((this.service.quoteItems[0].BundleWidthPallet + this.service.quoteItems[0].BundleAlongPallet) * this.service.quoteItems[0].Layer)))  * ((4 * this.service.quoteItems[0].BundleWidthPallet * this.service.quoteItems[0].WidthBoard) + (16 * thickness * this.service.quoteItems[0].BoxBundle * this.service.quoteItems[0].Layer) + (4 * ((this.service.quoteItems[0].LengthBoard / 2) +this.service.quoteItems[0].WidthBoard))) /1000000).toFixed(4));        
      this.service.quoteItems[0].SubTotCorrugator = parseFloat((this.service.quoteItems[0].CostMP + this.service.quoteItems[0].CostAuxCorr + this.service.quoteItems[0].CostCorrToConv + this.service.quoteItems[0].CostFixed + this.service.quoteItems[0].CostTape).toFixed(4));        
      this.service.quoteItems[0].CostWasteConv = parseFloat((this.service.quoteItems[0].SubTotCorrugator * 1.5 / 100).toFixed(4));
      if (this.service.quoteItems[0].PlateDie == true){
        this.service.quoteItems[0].SubTotConverting = parseFloat((this.service.quoteItems[0].CostMacConverting + this.service.quoteItems[0].CostInk + this.service.quoteItems[0].CostPVA + this.service.quoteItems[0].CostPlate + this.service.quoteItems[0].CostDie + this.service.quoteItems[0].CostCover + this.service.quoteItems[0].CostFilm + this.service.quoteItems[0].CostBoardPackaging + this.service.quoteItems[0].CostPallet +this.service.quoteItems[0].CostStrapping5mm + this.service.quoteItems[0].CostStrapping12mm + this.service.quoteItems[0].CostWasteConv).toFixed(4)); 
      } else {        
      this.service.quoteItems[0].SubTotConverting = parseFloat((this.service.quoteItems[0].CostMacConverting + this.service.quoteItems[0].CostInk + this.service.quoteItems[0].CostPVA + this.service.quoteItems[0].CostCover + this.service.quoteItems[0].CostFilm + this.service.quoteItems[0].CostBoardPackaging + this.service.quoteItems[0].CostPallet +this.service.quoteItems[0].CostStrapping5mm + this.service.quoteItems[0].CostStrapping12mm + this.service.quoteItems[0].CostWasteConv).toFixed(4)); 
      }
      this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(2));
    } else {
        if (this.boardList[indexSelected].TypeDensity == 0) {
          productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenLow * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
        } else if(this.boardList[indexSelected].TypeDensity == 1) {
          productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenMed * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
        } else {
          productionTimeCorr = (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenHig * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
        };
      
      //in this block, we will calculate production cost
      //if (this.boardCost.BoardID != null){
        productionTimeCorr = productionTimeCorr + 10;
        this.service.quoteItems[0].CostMP = parseFloat((this.boardCost.BoardPaperCostMM2 * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100)) * this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000).toFixed(4));
        this.service.quoteItems[0].CostMPNeto = this.service.quoteItems[0].CostMP;
        if (smOrder <= 1000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr01;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste01 / 100));
        } else if(smOrder <= 2000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr02;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste02 / 100));
        } else if(smOrder <= 3000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr03;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste03 / 100));
        } else if(smOrder <= 4000){
          productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr04;
          this.service.quoteItems[0].CostMP = this.service.quoteItems[0].CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 2).Waste04 / 100));
        }; 
        this.service.quoteItems[0].ProductionTimeCorr = productionTimeCorr;
        //The CostAuxCorrM2 is the cost by production sheetboard and sales sheetboard.
        this.service.quoteItems[0].CostAuxCorr = parseFloat(((this.boardCost.StarchCostMM2 + this.boardCost.CoatingCostMM2 + this.boardCost.OtherAuxCostMM2) * (this.service.quoteItems[0].TotalAreaRequired * this.service.formData.AverageOrder / 1000)).toFixed(4));        
        this.service.quoteItems[0].CostCorrToConv = parseFloat(((this.costrateList[0].DirLaborCostHR + this.costrateList[0].IndLaborCostHR + this.costrateList[0].MaintenanceCostHR + this.costrateList[0].EnergyCostoHR + this.costrateList[0].WaterCostHR + this.costrateList[0].GasCostHR + this.costrateList[0].IndManCostHR) * (productionTimeCorr / 60)).toFixed(4));
        this.service.quoteItems[0].CostFixed = parseFloat((this.costrateList[0].CorpMiscCostHR * productionTimeCorr / 60).toFixed(4));
                
        //this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + this.costrateList[workstation].IndLaborCostHR + this.costrateList[workstation].MaintenanceCostHR + this.costrateList[workstation].EnergyCostoHR + this.costrateList[workstation].WaterCostHR + this.costrateList[workstation].GasCostHR + this.costrateList[workstation].IndManCostHR + this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4));        
        
        //in this block, we are going to calculated the Strech Film cost.
        if (this.service.quoteItems[0].StrechFilm == true){
          costFilm = this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatCost * this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatRatCon * (1 + (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatWaste / 100));
          this.service.quoteItems[0].CostFilm = parseFloat((costFilm * (this.service.formData.AverageOrder / this.service.quoteItems[0].Layer)).toFixed(4));
        }else {
          this.service.quoteItems[0].CostFilm = 0;
        };
  
        //in this block, we are gonna calculated the Board for Packaging cost
        costBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatCost * this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatRatCon * (1 + (this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatWaste / 100));
        this.service.quoteItems[0].CostBoardPackaging = parseFloat((costBoardPackaging * (this.service.formData.AverageOrder / this.service.quoteItems[0].Layer)).toFixed(4));
        //in this block, we are gonna calculated the Pallet cost
        if (this.service.quoteItems[0].Pallet == true){
          costPallet = this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatCost * this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatRatCon * (1 + (this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatWaste / 100));
          this.service.quoteItems[0].CostPallet = parseFloat((costPallet * (this.service.formData.AverageOrder / this.service.quoteItems[0].Layer)).toFixed(4));
        }else {
          this.service.quoteItems[0].CostPallet = 0;
        };
        
        //in this block we are calculated the Tape
        if (this.service.quoteItems[0].TapeID != 1){
          costTape = this.auxiliaryList.find(tape => tape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatCost * (1 + (this.auxiliaryList.find(tape => tape.AuxMatID == this.service.quoteItems[0].TapeID).AuxMatWaste / 100));
        } else {
          this.service.quoteItems[0].CostTape = 0;
          this.service.quoteItems[0].TapeQuantity = 0;
        };
        thickness = this.boardList.find(thicknes => thicknes.BoardID == this.service.quoteItems[0].BoardID).Thickness; 
        costStrapping02 = this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatWaste / 100))
        this.service.quoteItems[0].CostStrapping12mm = parseFloat(((costStrapping02 / 1000) * (this.service.formData.AverageOrder / this.service.quoteItems[0].Layer) * 2 * ((this.service.quoteItems[0].Layer * thickness / 1000) + (3 * this.service.quoteItems[0].WidthBoard / 1000))).toFixed(4));        
        this.service.quoteItems[0].CostWasteConv = 0;
        this.service.quoteItems[0].SubTotCorrugator = parseFloat((this.service.quoteItems[0].CostMP + this.service.quoteItems[0].CostAuxCorr + this.service.quoteItems[0].CostCorrToConv + this.service.quoteItems[0].CostFixed + this.service.quoteItems[0].CostTape + this.service.quoteItems[0].CostFilm + this.service.quoteItems[0].CostPallet + this.service.quoteItems[0].CostBoardPackaging + this.service.quoteItems[0].CostStrapping12mm + this.service.quoteItems[0].CostWasteConv).toFixed(4));     
        this.service.quoteItems[0].SubTotConverting = 0; 
        this.service.quoteItems[0].Total = parseFloat((this.service.quoteItems[0].SubTotConverting + this.service.quoteItems[0].SubTotCorrugator).toFixed(2));
    };      
  };

  onQuoteBoxOrSheet(){
    if(this.service.qBoxOrSheet  == true){
      this.service.quoteBoxOrSheet = false;
    } else {
      this.service.quoteBoxOrSheet = true;
    };
  };

  onQuantityMin(){
    if(this.service.quoteItems.length == 0){
      this.toastr.error('No se tiene el item agregados a la cotización',  'Cartonero Digital.');
    }else {
    this.toastr.info('La cantidad mínima de pedido para este simbolo deberán ser: ' + Math.floor(3000 / this.service.quoteItems[0].BoxArea) + " Piezas", 'Cartonero Digital.');
    }
  }

  onContactName(){
    this.service.formData.ContactName = this.customerList.find(contactName => contactName.CustomerID == this.service.formData.CustomerID).ContactName;
    
  }

};
