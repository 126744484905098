import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-valor-cartonero-digital',
  templateUrl: './valor-cartonero-digital.html',
  styleUrls: ['./valor-cartonero-digital.scss']
})
export class Valor_Cartonero_Digital implements OnInit {
    imgSrc:string

  constructor() { }

  ngOnInit(): void {
  }

}
