import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Supplier } from 'src/app/shared/supplier.model';
import { SupplierService } from 'src/app/shared/supplier.service';
import { SupplierItemsComponent } from './supplier-items.component';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styles: []
})
export class SupplierComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataSupplier: SupplierService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataSupplier.getSupplierList().then(res => this.dataSupplier.dataSupplier = res as Supplier);
  }

  refreshList(){
    this.dataSupplier.getSupplierList().then(res => this.dataSupplier.dataSupplier = res as Supplier);
  };

  AddOrEditSupplierItem(supplierItemIndex, SupplierID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="70%";
    dialogConfig.height ="60%";
    dialogConfig.data = { supplierItemIndex, SupplierID };
    this.dialog.open(SupplierItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteSupplierItem(id: number){
    if (confirm('Estas seguro de querer eliminar el registro?')) {
      this.dataSupplier.deleteSupplier(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Cartonero Digital.');
      }, () =>{
          this.toastr.error('El Proveedor seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Cartonero Digital.');
      });
    };
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataSupplier.deleteSupplier(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Cartonero Digital.');
      });
    }; */
  };

  onFilterSupplierName(supplierName: string){
    this.dataSupplier.getSupplierListId(supplierName).then(res => this.dataSupplier.dataSupplier = res as Supplier);
  };
}
