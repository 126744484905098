import { Component, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'blog-sas-popup',
  template: `
  <div #myModal class="container">
  <div class="content">
    <p><IMG SRC="https://cartonero.digital/assets/img/cartonero-digital-256-verde-verde-encode-sans-256x106.png"></p>
    <button (click)="close()">Cerrar</button>
  </div>
  </div>
`,
  styleUrls: ['./blog-sas-popup.component.css'],
})
 
export class ModalComponent {
  @ViewChild('myModal', { static: false }) modal: ElementRef;

  open() {
    this.modal.nativeElement.style.display = 'block';
  }

  close() {
    this.modal.nativeElement.style.display = 'none';
  }
}
