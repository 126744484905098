import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuotesComponent } from './quotes/quotes.component';
import { QuoteComponent } from './quotes/quote/quote.component';
import { CustomerComponent } from './maintenance/customer/customer.component';
import { BoardComponent } from './maintenance/board/board.component';
import { PaperComponent } from './maintenance/paper/paper.component';
import { SupplierComponent } from './maintenance/supplier/supplier.component';
import { AuxiliaryComponent } from './maintenance/auxiliary/auxiliary.component';
import { WorkstationComponent } from './maintenance/workstation/workstation.component';
import { InkComponent } from './maintenance/ink/ink.component';
import { QuotehomeComponent } from './quotehome/quotehome.component';
import { FreightComponent } from './maintenance/freight/freight.component';
import { DestinyComponent } from './maintenance/destiny/destiny.component';
// Cartonero Digital 2.0
import { LandingEmail } from 'src/admin/landing-email/landing-email.component';
import { Tecnologia_Software_Como_Servicio_SAAS } from 'src/app/components/pages/blog-detail-sas/blog-detail-sas.component';
import { Cotizador_en_Linea } from './components/pages/blog-cartoneros/blog-cartoneros';
import { Blog_todo_cartonero } from './components/pages/blog-todo-cartonero/blog-todo-cartonero';
import { Empresa_Cartonero_Digital } from './components/pages/empresa-cartonero-digital/empresa-cartonero-digital';
import { Valor_Cartonero_Digital } from './components/pages/valor-cartonero-digital/valor-cartonero-digital';
import { Mercado_Meta } from './components/pages/mercado-meta/mercado-meta';
import { El_Carton_Corrugado } from './components/pages/blog-El-Carton-Corrugado/blog-El-Carton-Corrugado';
import { Diagrama_de_Arquitectura } from './components/pages/blog-Diagrama_de_Arquitectura/blog-Diagrama_de_Arquitectura';
import { Corrugadoras } from './components/pages/blog-Corrugadoras/blog-Corrugadoras';
import { MainHome } from './main-home/main-home';
import { AppComponent } from './components/pages/blog-detail-sas/blog.component';
// Video teca, pure html iframe
import { videoComponent } from './video/video.component';


const routes: Routes = [
    // { path:'', redirectTo:'quotehome', pathMatch:'full'},
    { path:'', redirectTo:'MainHome', pathMatch:'full'},
    { path:'quotes', component: QuotesComponent},
    { path:'quote', children:[
        { path:'', component: QuoteComponent},
        { path:'edit/:id',component: QuoteComponent}
    ]},
    { path:'board', component: BoardComponent},
    { path:'paper', component: PaperComponent},
    { path:'customer', component: CustomerComponent},
    { path:'supplier', component: SupplierComponent},
    { path:'freight', component: FreightComponent},
    { path:'destiny', component: DestinyComponent},
    
    //{ path:'updatepapercost', component: UpdatepapercostComponent},
    { path:'auxiliary', component: AuxiliaryComponent},
    { path:'workstation', component: WorkstationComponent},
    { path:'ink', component: InkComponent},
    { path:'quotehome', component: QuotehomeComponent},
    // Cartonero Digital 2.0
    {path: 'landingMail', component: LandingEmail},
    {path: 'Tecnologia_Software_Como_Servicio_SAAS', component: Tecnologia_Software_Como_Servicio_SAAS},
    {path: 'Cotizador_en_Linea', component: Cotizador_en_Linea},
    {path: 'Blog_todo_cartonero', component: Blog_todo_cartonero},
    {path: 'Empresa_Cartonero_Digital', component: Empresa_Cartonero_Digital},
    {path: 'Valor_Cartonero_Digital', component: Valor_Cartonero_Digital},
    {path: 'Mercado_Meta', component: Mercado_Meta},
    {path: 'El_Carton_Corrugado', component: El_Carton_Corrugado},
    {path: 'Diagrama_de_Arquitectura', component: Diagrama_de_Arquitectura},
    {path: 'Corrugadoras', component: Corrugadoras},
    {path: 'MainHome', component: MainHome},
    {path: 'AppComponent', component: AppComponent},
    {path: 'videoComponent', component: videoComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule {}

