import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-corrugadoras',
  templateUrl: './blog-Corrugadoras.html',
  styleUrls: ['./blog-Corrugadoras.scss']
})
export class Corrugadoras implements OnInit {
    imgSrc:string

  constructor() { }

  ngOnInit(): void {
  }

}
