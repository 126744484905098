import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Workstation } from 'src/app/shared/workstation.model';
import { WorkstationService } from 'src/app/shared/workstation.service';

@Component({
  selector: 'app-workstation-items',
  templateUrl: './workstation-items.component.html',
  styles: []
})
export class WorkstationItemsComponent implements OnInit {
  isValid: boolean = true;
  
  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<WorkstationItemsComponent>,
  public workstationService: WorkstationService,
  private toastr: ToastrService) { }

  ngOnInit(): void {  
    if (this.data.workstationItemIndex==null){
      this.workstationService.workstationList = {
        MachineID: '',
        MachineName: '',
        Type: '',
        ManufacturedBy: '',
        Brand: '',
        Model: '',
        TrimCorrugator: null,
        NumColor: null,
        WidthMin: null,
        WidthMax: null,
        LenghtMin: null,
        LenghtMax: null,
        SpeedRegHigDenHig: null,
        SpeedRegHigDenMed: null,
        SpeedRegHigDenLow: null,
        SpeedRegMedDenHig: null,
        SpeedRegMedDenMed: null,
        SpeedRegMedDenLow: null,
        SpeedRegLowDenHig: null,
        SpeedRegLowDenMed: null,
        SpeedRegLowDenLow: null,
        OperatingEfficiency: null,
      }
    }else {
        this.workstationService.workstationList = Object.assign({}, this.workstationService.dataWorkstation[this.data.workstationItemIndex]);
    };
  };

  onSubmit (form: NgForm){

    if (this.isValid == false){
      this.dialogRef.close();
    }else {
      if (this.validateForm(form.value)){
    //if (this.validateForm(form.value)){
        //if (this.isValid == true){
          //this.workstationService.workstationList.CountryID = +this.workstationService.workstationList.CountryID;
          //this.workstationService.workstationList.StateID = +this.workstationService.workstationList.StateID;
          if (this.data.workstationItemIndex == null)
            this.workstationService.postWorkstation().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Cartonero Digital.');
            });
          else
            this.workstationService.putWorkstationID(this.workstationService.workstationList.MachineID, this.workstationService.workstationList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Cartonero Digital.');
          });
          this.dialogRef.close();
        };
        this.isValid = true;
        
    //};
    };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formWorkstation: Workstation){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.workstationService.workstationList.MachineName == ""){
      this.getformFocus("MachineName", "Nombre de Máquina")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el ' + nameForm, 'Cartonero Digital.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

}
