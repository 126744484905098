import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-mercado-meta',
  templateUrl: './mercado-meta.html',
  styleUrls: ['./mercado-meta.scss']
})
export class Mercado_Meta implements OnInit {
    imgSrc:string

  constructor() { }

  ngOnInit(): void {
  }

}
