// Cartonero Digital 2.0 - Mayo 2023
// @MH

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Landing } from '../../app/shared/landing.model';
import { LandingService } from '../../app/shared/landing.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-landing-email',
  templateUrl: './landing-email.component.html',
  styleUrls: ['./landing-email.component.scss']
})
export class LandingEmail implements OnInit {

  landing: Landing = {lan_nombre:'', lan_email:'', lan_celular:'',lan_fecha:'',lan_tipo:'',lan_giro:'',lan_notas:''};

  error = '';
  success = '';

  constructor(   
    public landingService: LandingService,
    public dataLanding: LandingService,
    private toastr: ToastrService) { }

    ngOnInit() { }

    onSubmit(formulario: NgForm){
      this.resetAlerts();
      this.landingService.postLanding(this.landing).subscribe(
        (res: Landing)=>{
          formulario.reset();
          this.toastr.success('Gracias por registrarse, a la brevedad nos pondremos en contacto con usted !!!', 'Cartonero Digital.');
        }
      );
    };

    resetAlerts() {
      this.error = '';
      this.success = '';
    }
}