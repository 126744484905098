<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <h1>Tecnología y Software como Servicio | SaaS</h1>
    </div>
</div>
<br>
<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
             <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-img1.jpg" alt="blog-details"> -->
                        <div class="date">Blog Actualizado: 14 mayo 2024</div>
                    </div>
                    <div class="article-content">
                    <blockquote class="blockquote">
                        <h5>Cartonero Digital es un Software como Servicio, te describimos los pilares que lo hacen Confiable y Seguro:</h5>
                    </blockquote>
                    <br>
                        <ul class="category" >
                            <li><a routerLink="/blog">IDENTIDAD</a></li>&nbsp;&nbsp;
                            <li><a routerLink="/blog">DATOS</a></li>&nbsp;&nbsp;
                            <li><a routerLink="/blog">APLICACIONES</a></li>&nbsp;&nbsp;
                            <li><a routerLink="/blog">NUBE</a></li>&nbsp;&nbsp;
                            <li>SEGURIDAD ✔️</li>
                            
                        </ul>
                        <h4>Utilizamos Arquitectura de Seguridad multicapa que protege tu Datos desde el Navegador hasta la aplicación del Cotizador.</h4>
                        <p>Con la Protección de Microsoft Defender Cloud 🟰 Antivirus avanzado para Nube.
                        </p><br>
                        <h4>Con la aplicación de Autenticación, Identidad y Acceso solo se permiten usuarios autorizados y con privilegios definidos en tu instancia.</h4>
                        <p>Tú Contraseña encriptada ➕ Doble factor de autenticación🟰 SEGURIDAD. </p>
                        <br>
                        <blockquote class="blockquote">
                            <h4>En Cartonero Digital tus datos están protegidos con Alta Seguridad en la Nube desde Azure.</h4>
                        </blockquote>
                        <br>
                        <h4>Implementamos la Seguridad – Anti amenazas.</h4>
                        <p>Con Inteligencia Artificial y herramientas nativas en la Nube.</p>
                        <br>
                        <h4>Última generación de FireWall – Corta fuegos logrando un sólido blindaje y Protector de todo tipo de Virus.</h4>
                        <p>Aplicando una capa de protección entre Internet y la Nube.</p>
                        <br>
                        <h4>Con la Protección Ataques DDoS aseguramos la disponibilidad del Cotizador.</h4>
                        <p>Fuerte Filtro contra Ataques masivos-simultáneos.</p>
                        <br>
                        <h4>Definición de Procesos de Seguridad Integral y Gobernanza.</h4>
                        <p>Aplicamos políticas y estándares de ciberseguridad.</p>
                        <br>
                        <h4>Avanzada Protección de datos dinámico monitoreados y supervisados para su Integridad.</h4>
                        <p>Tus Datos cifrados y encriptados ➕  Certificado SSL en portal Cartonero Digital.</p>
                    </div>
                </div>
                
                <br>
                <!--div class="leave-a-reply">
                    <h4>Dejar un comentario, lo publicaremos aquí</h4>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="Su Nombre">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" placeholder="Su mejor correo electrónico">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Su comentario"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-red">Enviar su comentario</button>
                        </div>
                    </div>
                </div>
                <br-->

            </div>
        </div>
    </div>
</div>
<div *ngif="PopUp_diagram">
<app-blog-detail-sas></app-blog-detail-sas>
</div>

<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>