<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <h1>Cartonero Digital. &nbsp;&nbsp;◽&nbsp;&nbsp;¿El Cotizador es para mí? &nbsp;&nbsp;&nbsp;✅&nbsp; Beneficios.</h1>
    </div>
</div>
<br>
<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
             <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-img1.jpg" alt="blog-details"> -->
                        <div class="date">Actualizado: 14 mayo 2024</div>
                    </div>
                    <div class="article-content">
                    <blockquote class="blockquote">
                        <h5>Conoce las Funciones, Beneficios y Ventajas. &nbsp;&nbsp;<small>(lectura en 2 mins)</small></h5>
                    </blockquote>
                    <br>
                        <ul class="category" >
                            <li><a routerLink="/blog">CARACTERÍSTICAS</a></li>
                            <li><a routerLink="/blog">FUNCIONES</a></li>
                            <li><a routerLink="/blog">BENEFICIOS</a></li>
                            <li><a routerLink="/blog">VENTAJAS</a></li>
                            
                        </ul>
                        <h4>Las características que hacen de Cartonero Digital un Cotizador totalmente completo para tus costos 🟰 Datos, análisis y Control de Costos.</h4>
                        <p><small>➕ &nbsp;</small>Materias Primas, Materiales Auxiliares y herramentales.</p>
                        <p><small>➕ &nbsp;</small>Mano de Obra Directa e Indirecta.</p>
                        <p><small>➕ &nbsp;</small>Mantenimiento (mayor y menor).</p>
                        <p><small>➕ &nbsp;</small>Energía (agua, energía y combustible).</p>
                        <p><small>➕ &nbsp;</small>Fijos (sueldos de planta y corporativos).</p>
                        <p><small>➕ &nbsp;</small>Comisiones de venta y gastos financieros.</p>
                        <p><small>➕ &nbsp;</small>Gastos indirectos de fabricación.</p>
                        <br>
                        <blockquote class="blockquote">
                            <h4>Beneficios y Ventajas que obtendrás:</h4>
                        </blockquote>
                        <p><small>☑️ </small>Los datos base del cálculo están integrados, evita validarlos en cada cotización.</p>
                        <p><small>☑️ </small>Genera las combinaciones deseadas de resistencia, para determinar el costo idóneo de la cotización.</p>
                        <p><small>☑️ </small>Al calcular costos se obtiene un resultado certero lo que nos brinda un margen de ganancia realista.</p>
                        <p><small>☑️ </small>Una cotización con Cartonero Digital la realizas en unos minutos.</p>
                        <br><br>
                        <h4>¿Cómo lograrás estos benficios? 🟰 Con las funciones de Cartonero Digital.</h4>
                        <p><small>➖ </small>El Cotizador calcula el costo por m2 de cada resistencia.</p>
                        <p><small>➖ </small>Genera el Ratio como Factor de tiempo por hora de los costos variables y fijos. Son parametrizables y te ayudan a consideran el costo favorecedor de tu cotización.</p>
                        <p><small>➖ </small>Obten los costos de producción de las máquinas, considerando los gastos de mano de obra, energía, mantenimiento, costos fijos y gastos por el tiempo de uso.</p>
                        <p><small>➖ </small>Los datos y ratios base del cálculo de costos son configurables por el usuario, adaptandose a las necesidades de la empresa.</p>
                    </div>
                </div>
                <!--
                <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a routerLink="/blog-details"><i class="icofont-double-left"></i> Blog Anterior</a>
                    </div>
                    <div class="controls-right">
                        <a routerLink="/blog-details">Siguiente Blog <i class="icofont-double-right"></i></a>
                    </div>
                </div>
                -->
                <!-- 
                <div class="post-comments">
                    <h4>Comentarios</h4>
                    
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖-
                            <img src="assets/img/user1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h3>Ing. Gabriel Dorantes</h3>
                            <p>"Desde la primer semana que usarmos el Cotizador controlamos los Costos, y el margen de ganancia es real"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                           <img src="assets/img/user2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Lic. Eduardo Rodríguez</h4>
                            <p>"Me registré en el Portal, y me invitaron a una Demo en línea y ahora usamos todas las ventajas con Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖
                            <img src="assets/img/user3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Sr. Vicente Mireles</h4>
                            <p>"Por 1 mes con Cartonero Digital seguimos utilizando un Excel complejo, y poco después ya todo es con el Cotizador"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                            <img src="assets/img/user4.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Ing. Juan Antonio Pérez</h4>
                            <p>"Por años buscamos una solución para cotizar Cajas de Cartón, por fin existe una y la mejor Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                
                </div>
                -->
               
                <!--div class="leave-a-reply">
                    <h4>Dejar un comentario, lo publicaremos aquí</h4>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="Su Nombre">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" placeholder="Su mejor correo electrónico">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Su comentario"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-red">Enviar su comentario</button>
                        </div>
                    </div>
                </div-->
                <br>

            </div>
        </div>
    </div>
</div>

<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>