import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EctService {

  constructor(private http: HttpClient) { }

  getEctList(){
    return this.http.get(environment.apiURL + '/Ect').toPromise();
  };

  getEctListId(ectID: number){
    return this.http.get(environment.apiURL + '/Ect/'+ ectID).toPromise();
  };
}
