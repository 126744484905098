import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Country } from 'src/app/shared/country.model';
import { CountryService } from 'src/app/shared/country.service';
import { Customer } from 'src/app/shared/customer.model';
import { CustomerService } from 'src/app/shared/customer.service';
import { State } from 'src/app/shared/state.model';
import { StateService } from 'src/app/shared/state.service';


@Component({
  selector: 'app-customer-items',
  templateUrl: './customer-items.component.html',
  styles: []

})
export class CustomerItemsComponent implements OnInit {
  countryList: Country [];
  stateList: State [];
  listState: State [];
  isValid: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data,
    public dialogRef: MatDialogRef<CustomerItemsComponent>,
    public customerService: CustomerService, private countryService: CountryService,
    private stateService: StateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.countryService.getCountryList().then(res => this.countryList = res as Country[]);
    this.stateService.getStateList().then(res => this.stateList = res as State[]);
    this.stateService.getStateList().then(res => this.listState = res as State[]);
  
    if (this.data.customerItemIndex==null){
      this.customerService.customerList = {
        CustomerID: null,
        CustomerRFC: '',
        CustomerName: '',
        CustomerTradeName: '',
        ContactName: '',
        ContactPosition: '',
        CountryID: 0,
        StateID: 0,
        Address: '',
        ZipCode: '',
        Telephone: '',
        Email: '',
        CustomerType: '',
        TargetProfitability: 0
      }
    }else {
        this.customerService.customerList = Object.assign({}, this.customerService.dataCustomer[this.data.customerItemIndex]);
    };
  };

  onSubmit (form: NgForm){

    if (this.isValid == false){
      this.dialogRef.close();
    }else {
      if (this.validateForm(form.value)){
    //if (this.validateForm(form.value)){
        //if (this.isValid == true){
          this.customerService.customerList.CountryID = +this.customerService.customerList.CountryID;
          this.customerService.customerList.StateID = +this.customerService.customerList.StateID;
          if (this.data.customerItemIndex == null)
            this.customerService.postCustomer().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Cartonero Digital.');
            });
          else
            this.customerService.putCustomerID(this.customerService.customerList.CustomerID, this.customerService.customerList).subscribe(res => {
              this.toastr.success('Actualizacion exitosa!', 'Cartonero Digital.');
          });
          this.dialogRef.close();
        };
        this.isValid = true;
        
    //};
    };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formCustomer: Customer){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onSelectCountry(countryID: number){
      this.stateService.getStateList().then(res => this.listState = res as State []);
      this.stateList = this.listState.filter(item => item.CountryID == countryID);
  };

  onvalidateForm(){
    if (this.customerService.customerList.CustomerName == ""){
      this.getformFocus("CustomerName", "Razón Social")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el ' + nameForm, 'Cartonero Digital.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

}
