import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Development } from 'src/app/shared/development.model'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevelopmentService {

  constructor(private http: HttpClient) { }

  //getDevelopmentList(styleID: number, fluteID: number, closureID: number): any{
  //  return this.http.get(environment.apiURL + '/Development/' + styleID + '/' + fluteID +'/' + closureID).toPromise();
  //}

  //getDevelopmentList(styleID: number, fluteID: number, closureID: number): any{
    //return this.http.get(environment.apiURL + '/Development/' + styleID + '/' + fluteID +'/' + closureID).toPromise();
  //};

  getDevelopmentList(styleID: number, fluteID: number, closureID: number): Observable<Development[]>{
    return this.http.get<Development[]>(environment.apiURL + '/Development/' + styleID + '/' + fluteID +'/' + closureID);
  };
}
