import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../shared/quote.service';

@Component({
  selector: 'app-quotehome',
  templateUrl: './quotehome.component.html',
  styles: []
})
export class QuotehomeComponent implements OnInit {

  constructor(public service: QuoteService) { }

  ngOnInit(): void {
  }

  onQuoteBox(){
    this.service.qBoxOrSheet = true;

  }

  onQuoteSheet(){
    this.service.qBoxOrSheet = false;
  }
}
