import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Landing } from './landing.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
  })
  export class LandingService {
    landingList: Landing;
    dataLanding: Landing;

    constructor(private http: HttpClient, private toastr: ToastrService) { }

    postLanding(list) {
        return this.http.post(environment.apiURL + '/Landing', list);
      };

  }