<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="BoardIDFilter" id="BoardIDFilter" class="form-control" placeholder="Digita la Resistencia a buscar..." 
        (keyup)="onFilterBoardID($event.target.value)">
    </div>
    <div class="form-group" style="margin-left: 380px; margin-top: 5px;">
        <a class="btn btn-outline-success" 
        style="font-weight:bold; height: 40px; width: 300px; text-align: center; vertical-align:middle;"
        (click)="onUpdateBoardCost()"><i class="fa fa-dollar"></i> Actualizar Costos de Resistencias</a>
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>ID Resistencia</th>
        <th hidden = "true">FluteID</th>
        <th hidden = "true">BoardFlute</th>
        <th hidden = "true">ECT</th>
        <th hidden = "true">ColorPaperExt</th>
        <th hidden = "true">TypeStarch</th>
        <th hidden = "true">CoatingTo</th>
        <th hidden = "true">Alternative</th>
        <th hidden = "true">Coating</th>
        <th hidden = "true">FluteCoating</th>
        <th hidden = "true">ApprovedBoard</th>
        <th>Liner 01</th>
        <th>Medium 01</th>
        <th>Liner 02</th>
        <th>Medium 02</th>
        <th>Liner 03</th>
        <th style="text-align: center;">Tipo de Mercado</th>
        <th hidden = "true">Note</th>
        <th hidden = "true">EstimatedECT</th>
        <th hidden = "true">WasteCorrugatorSTD</th>
        <th hidden = "true">WasteCorrugatorOtherApp</th>
        <th>Gramaje gr/m2</th>
        <th hidden = "true">TypeDensity</th>
        <th hidden = "true">Thickness</th>
        <th>
            <a class="btn btn-outline-success" (click)="AddOrEditBoardItem(null, dataBoard.dataBoard.BoardID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataBoard.dataBoard; let i=index;">
            <th (click)="AddOrEditBoardItem(i, item.BoardID)">{{item.BoardID}}</th>
            <td hidden = "true">{{item.FluteID}}</td>
            <td hidden = "true">{{item.BoardFlute}}</td>
            <td hidden = "true">{{item.ECT}}</td>
            <td hidden = "true">{{item.ColorPaperExt}}</td>
            <td hidden = "true">{{item.TypeStarch}}</td>
            <td hidden = "true">{{item.CoatingTo}}</td>
            <td hidden = "true">{{item.Alternative}}</td>
            <td hidden = "true">{{item.Coating}}</td>
            <td hidden = "true">{{item.FluteCoating}}</td>
            <td hidden = "true">{{item.ApprovedBoard}}</td>
            <td>{{item.PaperID01}}</td>
            <td>{{item.PaperID02}}</td>
            <td>{{item.PaperID03}}</td>
            <td>{{item.PaperID04}}</td>
            <td>{{item.PaperID05}}</td>
            <td *ngIf="item.TypeMarket=='MI';" style="text-align: center;">Industrial</td>
            <td *ngIf="item.TypeMarket=='MA';" style="text-align: center;">Agrícola</td>
            <td hidden = "true">{{item.Note}}</td>
            <td hidden = "true">{{item.EstimatedECT}}</td>
            <td hidden = "true">{{item.WasteCorrugatorSTD}}</td>
            <td hidden = "true">{{item.WasteCorrugatorOtherApp}}</td>
            <td style="text-align: right;">{{item.Density}}</td>
            <td hidden = "true">{{item.TypeDensity}}</td>
            <td hidden = "true">{{item.Thickness}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditBoardItem(i, item.BoardID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-success text-white ml-1" (click)="onEditBoardCostItem(i, item.BoardID)"><i class="fa fa-dollar"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteBoardItem(item.BoardID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>

