import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from './blog-sas-popup/blog-sas-popup.compnent';

@Component({
  selector: 'app-blog-detail-sas',
  template: `
  <blog-sas-popup #modal></blog-sas-popup>
  <p>Consulta</p>
  <button (click)="openModal()">Diagrama</button>
`,
  templateUrl: './blog-detail-sas.component.html',
    styleUrls: ['./blog-detail-sas.component.scss'],
})

export class Tecnologia_Software_Como_Servicio_SAAS implements OnInit {
//  imgSrc:string;
  PopUP_diagram = true;

  @ViewChild('modal', {static: false}) modal: ModalComponent

  openModal() {
    this.modal.open();
  }

  constructor() { }

  ngOnInit(): void {
  }
  
}
