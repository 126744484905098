import { Component } from '@angular/core';
import { QuoteService } from './shared/quote.service';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
  //styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Cartonero Digital';
  iframeUrl: SafeResourceUrl;
  
  constructor(public service: QuoteService) {}
};



