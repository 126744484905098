import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';



@Component({
  selector: 'app-blog-cartoneros',
  templateUrl: './blog-cartoneros.html',
  styleUrls: ['./blog-cartoneros.scss']
})
export class Cotizador_en_Linea implements OnInit {
    imgSrc:string

  constructor(private titleService: Title, private metaService: Meta) {}
  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
    }

  ngOnInit(): void {
  }



  
}


