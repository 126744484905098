import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Destiny } from 'src/app/shared/destiny.model';
import { DestinyService } from 'src/app/shared/destiny.service';
import { Freight } from 'src/app/shared/freight.model';
import { FreightService } from 'src/app/shared/freight.service';
import { TruckType } from 'src/app/shared/TruckType.model';
import { TruckTypeService } from 'src/app/shared/TruckType.service';
import { Freightitem } from 'src/app/shared/freightitem.model';

@Component({
  selector: 'app-freight-items',
  templateUrl: './freight-items.component.html',
  styles: []
})

export class FreightItemsComponent implements OnInit {

  destinyList: Destiny [];
  truckList: TruckType [];
  //listState: State [];
  isValid: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<FreightItemsComponent>,
  public freightService: FreightService, private destinyService: DestinyService,
  private truckService: TruckTypeService, 
  private toastr: ToastrService) { }

  ngOnInit(): void {
    this.destinyService.getDestinyList().then(res => this.destinyList = res as Destiny[]);
    this.truckService.getTruckTypeList().then(res => this.truckList = res as TruckType[]);
    //this.stateService.getStateList().then(res => this.listState = res as State[]);
    //this.freightService.freight.FreightID==

    if (this.data.freightItemIndex==null){
      this.freightService.freightList = {
        FreightID: null,
        DestinyID: null,
        Address: null,
        TruckID: null,
        TruckName: null,
        FreightCost: 0,
      }
      this.freightService.freight = {
        FreightID: null,
        DestinyID: null,
        TruckID: null,
        FreightCost: 0,
      }
    }else {
        this.freightService.freightList = Object.assign({}, this.freightService.dataFreight[this.data.freightItemIndex]);
        this.freightService.freight = {
          FreightID: +this.freightService.freightList.FreightID,
          DestinyID: +this.freightService.freightList.DestinyID,
          TruckID: +this.freightService.freightList.TruckID,
          FreightCost: +this.freightService.freightList.FreightCost,
        };
        //this.freightService.freight.FreightID = Number(this.freightService.freightList.FreightID);
        //this.freightService.freight.DestinyID = Number(this.freightService.freightList.DestinyID);
        //this.freightService.freight.TruckID = Number(this.freightService.freightList.TruckID);
        //this.freightService.freight.FreightCost = Number(this.freightService.freightList.FreightCost);
    };
  }

  onSubmit (form: NgForm){

    if (this.isValid == false){
      this.dialogRef.close();
    }else {
      if (this.validateForm(form.value)){
    //if (this.validateForm(form.value)){
        //if (this.isValid == true){
          //this.freightService.freightList.CountryID = +this.freightService.freightList.CountryID;
          //this.freightService.freightList.StateID = +this.freightService.freightList.StateID;
          this.freightService.freight.FreightID = +this.freightService.freightList.FreightID;
          this.freightService.freight.DestinyID = Number(this.freightService.freightList.DestinyID);
          this.freightService.freight.TruckID = Number(this.freightService.freightList.TruckID);
          this.freightService.freight.FreightCost = Number(this.freightService.freightList.FreightCost);
          if (this.data.freightItemIndex == null)
            this.freightService.postFreight().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Cartonero Digital.');
            });
          else
            this.freightService.putFreightID(this.freightService.freightList.FreightID, this.freightService.freight).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Cartonero Digital.');
          });
          this.dialogRef.close();
        };
        this.isValid = true;
        
    //};
    };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formFreight: Freight){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onSelectCountry(countryID: number){
      //this.stateService.getStateList().then(res => this.listState = res as State []);
      //this.stateList = this.listState.filter(item => item.CountryID == countryID);
  };

  onvalidateForm(){
    if (this.freightService.freightList.DestinyID == 0){
      this.getformFocus("CustomerName", "Razón Social")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el ' + nameForm, 'Cartonero Digital.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

}
