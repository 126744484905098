import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from './blog-sas-popup/blog-sas-popup.compnent';

@Component({
    selector: 'app-blog',
    template: `
    <p>Consulta...</p>
    <blog-sas-popup #modal></blog-sas-popup>
    <p>Consulta</p>
    <button (click)="openModal()">Diagrama</button>
  `,
    templateUrl: './blog.component.html',
  })
  
  export class AppComponent {
  //  imgSrc:string;
    PopUP_diagram = true;
  
    @ViewChild('modal', {static: false}) modal: ModalComponent
  
    openModal() {
      this.modal.open();
    }
 
    
  }
  