import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Destiny } from 'src/app/shared/destiny.model';
import { DestinyService } from 'src/app/shared/destiny.service';
import { DestinyItemsComponent } from './destiny-items.component';

@Component({
  selector: 'app-destiny',
  templateUrl: './destiny.component.html',
  styles: []
})
export class DestinyComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataDestiny: DestinyService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataDestiny.getDestinyList().then(res => this.dataDestiny.dataDestiny = res as Destiny);
  }

  refreshList(){
    this.dataDestiny.getDestinyList().then(res => this.dataDestiny.dataDestiny = res as Destiny);
  };

  AddOrEditDestinyItem(destinyItemIndex, DestinyID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height ="50%";
    dialogConfig.data = { destinyItemIndex, DestinyID };
    this.dialog.open(DestinyItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteDestinyItem(id: number){
    if (confirm('Estas seguro de querer eliminar el Destino seleccionado?')) {
      this.dataDestiny.deleteDestiny(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Cartonero Digital.');
      }, () =>{
          this.toastr.error('El Destino seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Cartonero Digital.');
      });
    };
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataDestiny.deleteDestiny(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Cartonero Digital.');
      });
    }; */
  };

  onFilterDestinyName(destinyName: string){
    this.dataDestiny.getDestinyListId(destinyName).then(res => this.dataDestiny.dataDestiny = res as Destiny);
  };
}

