import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Destiny } from './destiny.model';

@Injectable({
  providedIn: 'root'
})
export class DestinyService {
  destinyList: Destiny;
  dataDestiny: Destiny;
  constructor(private http: HttpClient) { }

  getDestinyList(){
    return this.http.get(environment.apiURL + '/Destiny').toPromise();
  }
  getDestinyID(id: number) {
    return this.http.get(environment.apiURL + '/Destiny/' + id).toPromise();
  };

  getDestinyListId(destinyName: string){
    return this.http.get(environment.apiURL + '/Destiny/'+ destinyName).toPromise();
  }

  putDestinyID(id: number, destiny: Destiny) {
    return this.http.put(environment.apiURL + '/Destiny/' + id, destiny);

  };

  postDestiny() {
    return this.http.post(environment.apiURL + '/Destiny', this.destinyList);
  };

  deleteDestiny(id: number) {
    return this.http.delete(environment.apiURL + '/Destiny/' + id).toPromise();
  };
}
