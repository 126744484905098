import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http: HttpClient) { }

  getStateList(){
    return this.http.get(environment.apiURL + '/State').toPromise();
  };

  getStateListId(stateID: number){
    return this.http.get(environment.apiURL + '/State/'+ stateID).toPromise();
  };

}
