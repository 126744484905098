import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Customer } from '../../shared/customer.model';
import { CustomerService } from '../../shared/customer.service';
import { CustomerItemsComponent } from './customer-items.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styles: []
})

export class CustomerComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataCustomer: CustomerService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.dataCustomer.getCustomerList().then(res => this.dataCustomer.dataCustomer = res as Customer);
  }

  refreshList(){
    this.dataCustomer.getCustomerList().then(res => this.dataCustomer.dataCustomer = res as Customer);
  };

  AddOrEditCustomerItem(customerItemIndex, CustomerID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="70%";
    dialogConfig.height ="70%";
    dialogConfig.data = { customerItemIndex, CustomerID };
    this.dialog.open(CustomerItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteCustomerItem(id: number){
    if (confirm('Estas seguro de querer eliminar el registro?')) {
      this.dataCustomer.deleteCustomer(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Cartonero Digital.');
      }, () =>{
          this.toastr.error('El cliente seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Cartonero Digital.');
      });
    };

    /* if (confirm('Estas seguro de querer eliminar el registro?') == true) {
      this.dataCustomer.deleteCustomer(id).then(res =>{
        this.catchError = 1; 
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Cartonero Digital.');
      });
    } else {
      this.catchError = 1;
    };
        if (this.catchError == 0){
          this.toastr.error('El cliente seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Cartonero Digital.');
        }; */
  };

  onFilterCustomerName(customerName: string){
    this.dataCustomer.getCustomerListId(customerName).then(res => this.dataCustomer.dataCustomer = res as Customer);
  };
}
