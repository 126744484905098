<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="AuxiliaryIDFilter" id="AuxiliaryIDFilter" class="form-control" placeholder="Digite el Auxiliar a buscar..." 
        (keyup)="onFilterAuxiliaryName($event.target.value)">
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>Nombre</th>
        <th>Consumo</th>
        <th>Unidad</th>
        <th style="text-align: right;">Costo</th>
        <th>Unidad</th>
        <th style="text-align: right;">Desperdicio %</th>
        <th style="text-align: center;">Vida Útil (mes)</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditAuxiliaryItem(null, dataAuxiliary.dataAuxiliary.AuxMatID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataAuxiliary.dataAuxiliary; let i=index;">
            <th (click)="AddOrEditAuxiliaryItem(i, item.AuxiliaryID)">{{item.AuxMatID}}</th>
            <td>{{item.AuxMatName}}</td>
            <td style="text-align: right;">{{item.AuxMatRatCon}}</td>
            <td>{{item.AuxMatUniCon}}</td>
            <td style="text-align: right;">{{item.AuxMatCost}}</td>
            <td>{{item.AuxMatCostUni}}</td>
            <td style="text-align: right;">{{item.AuxMatWaste}}</td>
            <td style="text-align: center;">{{item.AuxLifeTime}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditAuxiliaryItem(i, item.AuxMatID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteAuxiliaryItem(item.AuxMatID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>


