<h4 class="display-4">Mantenimiento de Destinos </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>ID Destino</label>
            <input name="DestinyID" #DestinyID="ngModel" [(ngModel)]="destinyService.destinyList.DestinyID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-3">
            <label>País</label>
            <select name="CountryID" id="CountryID" #CountryID="ngModel" [(ngModel)]="destinyService.destinyList.CountryID" class="form-control"
                (change)="onSelectCountry($event.target.value)">
                <option value=0>-Select-</option>
                <option *ngFor="let item of countryList" value="{{item.CountryID}}">{{item.CountryName}}</option>
            </select>  
        </div>
        <div class="form-group col-md-4">
            <label>Estado</label>
            <select name="StateID" id="StateID" #StateID="ngModel" [(ngModel)]="destinyService.destinyList.StateID" class="form-control">
                <option value=0>-Select-</option>
                <option *ngFor="let item of stateList" value="{{item.StateID}}">{{item.StateName}}</option>
            </select>   
        </div>
        <div class="form-group col-md-4">
            <label>Ciudad</label>
            <input name="City" #City="ngModel" [(ngModel)]="destinyService.destinyList.City" class="form-control">  
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Municipio</label>
            <input name="Municipality" #Municipality="ngModel" [(ngModel)]="destinyService.destinyList.Municipality" class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>Código Postal</label>
            <input name="ZipCode" id="ZipCode" #ZipCode="ngModel" [(ngModel)]="destinyService.destinyList.ZipCode" class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>Distancia</label>
            <div class="input-group">
                <input name="Distance" id="Distance" #Distance="ngModel" [(ngModel)]="destinyService.destinyList.Distance" class="form-control" 
                type="number" min="0">  
                <div class="input-group-prepend">
                    <div class="input-group-text">Km</div>
                </div> 
            </div>
        </div> 
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
        
    </div>
</form>
 
