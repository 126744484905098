<div id="home" class="main-banner main-banner-logo">
 
            <div class="container main-banner-fade-in-image">
                <h1>Cartonero Digital</h1>          
                <h3>Cotizador de Cartón Corrugado</h3>
                <div class="main-banner-content">
                    <A href="../../assets/docs/Cartonero_Digital_SA_de_CV-Presentación_Empresarial-2023.pdf" target="_blank" class="btn btn-blue btn-right" >Conócenos</A>
                    <br><br>
                    <a href="/landingMail" class="btn btn-blue">Agenda tu Prueba</a>
                    <div id="slider">
                        <div id="layerslider_5" class="ls-wp-container" style="width:100%;height:524px;margin:0 auto;margin-bottom: 0px;">
                           
                        </div>
                        
                        <!--
                        <div class="ls-slide" data-ls="slidedelay:7000; transition2d: all;"><img src="https://protenismetepec.com/images/sliders/blank.gif" data-src="https://protenismetepec.com/images/sliders/Atardecer-Protenis-Val_Villarreal.jpg" height="524" width="1536" class="ls-bg" alt="Foto (c) Val Villarreal" /><img class="ls-l" style="top:70px;left:5px;white-space: nowrap;" data-ls="offsetxin:-100;durationin:4000;delayin:200;" src="https://protenismetepec.com/images/sliders/aprende (1).png" data-src="https://protenismetepec.com/images/sliders/aprende (1).png" alt="">
                            <img class="ls-l" style="top:110px;left:800px;white-space: nowrap;" data-ls="offsetxin:-300;durationin:2000;delayin:300;" src="https://protenismetepec.com/images/sliders/aprende (2).png" data-src="https://protenismetepec.com/images/sliders/aprende (2).png" alt="">
                        </div>
                        -->
                    </div>           
                </div>
            </div>

    <div class="down_arrow">
        <a (click)="onClick('about')" class="scroll_down"></a>
    </div>
</div>
<!-- Floating WhatsApp icon -->
<!--a href="https://cartonero.net/wa-cotizador"
   		 class="float-whatsapp" target="_blank">

<i class="icofont icofont-brand-whatsapp my-float-whatsapp"></i>
</a-->
<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>