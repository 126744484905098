import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Paper } from './paper.model';

@Injectable({
  providedIn: 'root'
})
export class PaperService {
  paperList: Paper;
  dataPaper: Paper;

  constructor(private http: HttpClient) { }

  getPaperList(){
    return this.http.get(environment.apiURL + '/Paper').toPromise();
  };

  getPaperId(paperNomenclature: string){
    return this.http.get(environment.apiURL + '/Paper/'+ paperNomenclature).toPromise();
  };

  deletePaper(paperID: number) {
    return this.http.delete(environment.apiURL + '/Paper/' + paperID).toPromise();
  };

  putPaperID(id: number, paper: Paper) {
    return this.http.put(environment.apiURL + '/Paper/' + id, paper);
  };

  putPaperCost(paperType: string, increaseCost: number, increasePercentage: number, paper: Paper) {
    return this.http.put(environment.apiURL + '/Paper/' + paperType + '/' + increaseCost + '/' + increasePercentage, paper);
  };

  postPaper() {
    return this.http.post(environment.apiURL + '/Paper', this.paperList);
  };
}
