import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuoteItem } from 'src/app/shared/quote-item.model';
import { QuoteService } from 'src/app/shared/quote.service';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';
import { TapeService } from 'src/app/shared/tape.service';
import { Tape } from 'src/app/shared/tape.model';
import { WorkstationService } from 'src/app/shared/workstation.service';
import { Workstation } from 'src/app/shared/workstation.model';
import { CostrateService } from 'src/app/shared/costrate.service';
import { Costrate } from 'src/app/shared/costrate.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { Product } from 'src/app/shared/product.model';
import { ProductService } from 'src/app/shared/product.service';


import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';

@Component({
  selector: 'app-quote-sheet',
  templateUrl: './quote-sheet.component.html',
  styleUrls: []
})
export class QuoteSheetComponent implements OnInit {
  formData: QuoteItem;
  boardList: Board [];
  boardCost: Boardcost;
  tapeList: Tape [];
  costrateList: Costrate [];
  workstationList: Workstation [];
  auxiliaryList: Auxiliary [];
  productList: Product [];

  isValid: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data,
    public dialogRef: MatDialogRef<QuoteSheetComponent>,
    private quoteService: QuoteService, private boardService: BoardService, 
    private tapeService: TapeService, private boardcostService: BoardcostService, 
    private workstationService: WorkstationService, private costrateService: CostrateService, 
    private auxiliaryService: AuxiliaryService, private productService: ProductService,
    private toastr: ToastrService) { }

    ngOnInit(): void {
      this.boardService.getBoardListOneAlternative("quoteSales", 1).then(res => this.boardList = res as Board[]);
      this.tapeService.getTapeList().then(res => this.tapeList = res as Tape[]);
      this.workstationService.getWorkstationList().then(res => this.workstationList = res as Workstation[]);
      this.costrateService.getCostRateList().then(res => this.costrateList = res as Costrate[]);
      this.auxiliaryService.getAuxiliaryList().then(res => this.auxiliaryList = res as Auxiliary[]);
      this.productService.getProductList().then(res => this.productList = res as Product[]);
  
      if (this.data.quoteItemIndex==null)
        this.formData = {
          QuoteItemID: null, 
          QuoteID: this.data.QuoteID,
          FluteID: 0,
          BoardID: null,
          StyleID: 6,
          StyleName: "Lamina de Carton Corrugado",
          Overlap: 0,
          ClosureID: 1,
          Register:"",
          DieType: "",
          TapeID: 1,
          TapeQuantity: 0,
          Width: 0,
          Length: 0,
          Depth: 0,
          Output: 0,
          Score: '',
          WidthBoard: 0,
          LengthBoard: 0,
          AddTrim:0,
          BoxArea: 0,
          DensityBoard: 0,
          BoxWeight: 0,
          AreaShipment: 0,
          TotalAreaRequired: 0,
          TotalWeightRequired: 0,
          InkNumber: 0,
          InkID01: null,
          PerInk01: 0,
          PerPrintingPlate01: 0,
          InkID02: null,
          PerInk02: 0,
          PerPrintingPlate02: 0,
          InkID03: null,
          PerInk03: 0,
          PerPrintingPlate03: 0,
          InkID04: null,
          PerInk04: 0,
          PerPrintingPlate04: 0,
          InkID05: null,
          PerInk05: 0,
          PerPrintingPlate05: 0,
          InkID06: null,
          PerInk06: 0,
          PerPrintingPlate06: 0,
          BoxBundle: 0,
          StrapDirection: "Sin Fleje",
          StrapNumber: 0,
          BundleWidthPallet: 0,
          BundleAlongPallet: 0,
          Layer: 0,
          StrechFilm: false,
          Pallet: false,
          PlateDie: false,
          ProductionTimeCorr: 0,
          ProductionTimeConv: 0,
          CostMPNeto: 0,
          CostMP: 0,
          CostAuxCorr: 0,
          CostCorrToConv: 0,
          CostFixed: 0,
          SubTotCorrugator: 0,
          CostMacConverting: 0,
          CostInk: 0,
          CostPlateTotal: 0,
          CostDieTotal: 0,
          CostPlate: 0,
          CostDie: 0,
          CostPVA: 0,
          CostTape: 0,
          CostCover: 0,
          CostFilm: 0,
          CostBoardPackaging: 0,
          CostPallet: 0,
          CostStrapping5mm: 0,
          CostStrapping12mm: 0,
          CostWasteConv: 0,
          SubTotConverting: 0,
          //consumptions materials
          ConsStarch: 0,
          ConsCoating: 0,
          ConsPVA: 0,
          ConsDieRDC: 0,
          ConsDieFDC: 0,
          ConsStringKing: 0,
          ConsOpenSesame: 0,
          ConsCover: 0,
          ConsInk01: 0,
          ConsInk02: 0,
          ConsInk03: 0,
          ConsInk04: 0,
          ConsInk05: 0,
          ConsInk06: 0,
          ConsPlate01: 0,
          ConsPlate02: 0,
          ConsPlate03: 0,
          ConsPlate04: 0,
          ConsPlate05: 0,
          ConsPlate06: 0,
          ConsStrapping5mm: 0,
          ConsStrapping12mm: 0,
          ConsStrechFilm: 0,
          ConsPallet: 0,
          ConsBoardPackaging: 0,
          ConsWater: 0,
          ConsGasForklift: 0,
          ConsGasBoiler: 0,
          Total:0
        }
       else
          this.formData = Object.assign({}, this.quoteService.quoteItems[this.data.quoteItemIndex]);
          if (this.formData.BoardID == null){
            this.boardcostService.getBoardCostListId("C-19-K-MI-AI-XX-1").subscribe((boardCosts: Boardcost) => {
              this.boardCost = boardCosts}, error => console.error(error));
          } else {
            this.boardcostService.getBoardCostListId(this.formData.BoardID).subscribe((boardCosts: Boardcost) => {
              this.boardCost = boardCosts}, error => console.error(error));
          };
    }
  
    onSubmit (form: NgForm){
    
      if (this.validateForm(form.value)){
        if (this.data.quoteItemIndex == null)
          //this.quoteService.quoteItems.push(form.value);
          this.quoteService.quoteItems.push(this.formData);
        else
          //this.quoteService.quoteItems[this.data.quoteItemIndex] = form.value;
          this.quoteService.quoteItems[this.data.quoteItemIndex] = this.formData;
          this.dialogRef.close();
      };
      this.isValid = true;
    };
    
  //validateForm(formData: QuoteItem){
    validateForm(formData: QuoteItem){
      this.onvalidateForm();
      if (this.isValid == false){
        exit;
      }else {
        this.isValid = true;
        this.onBoardCost();
        //formData = this.formData;
       /*  for (let i in this.formData){
          this.quoteItems[i].ItemID=Number(this.quoteItems[i].ItemID);
          this.quoteItems[i].Quantity=Number(this.quoteItems[i].Quantity);   
        } */
        
      };
      return this.isValid;
      
    };
  
    updateToleranceBoard(tolerance){ 
      if (tolerance.selectedIndex == 0 ) {
  
        this.formData.FluteID = 0;
        this.formData.BoardID = '';
      } 
      else {
        this.formData.FluteID = this.boardList[tolerance.selectedIndex-1].FluteID;
        this.formData.BoardID = this.boardList[tolerance.selectedIndex-1].BoardID;
        this.formData.DensityBoard = this.boardList[tolerance.selectedIndex-1].Density;
        this.ongetBoardCost();
        this.onLayer();
      };
    };
  
    onDevelopmentBox (){
        this.formData.LengthBoard = this.formData.Length
        this.formData.WidthBoard = this.formData.Width
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
        this.onLayer();
        this.ongetBoardCost();
    };
    
    //call costs of Board
    onBoardCost(){
      let productionTimeCorr: number; let indexSelected: number;
      let costStrapping02: number = 0; let thickness: number = 0;
      let costTape: number = 0; let costFilm: number = 0; 
      let costBoardPackaging: number = 0; let costPallet: number = 0;
      let smOrder: number;
  
      //in this block, we are going to calculated the production Time for corrugator
      smOrder = this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired
      indexSelected = this.boardList.findIndex(typeDensity => typeDensity.BoardID == this.formData.BoardID);    
      if (this.boardList[indexSelected].TypeDensity == 0) {
        productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenLow * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      } else if(this.boardList[indexSelected].TypeDensity == 1) {
        productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenMed * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      } else {
        productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenHig * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      };
      
      //in this block, we will calculate production cost
      productionTimeCorr = productionTimeCorr + 1;
      //this.formData.ProductionTimeCorr = productionTimeCorr;
      if (this.boardCost.BoardID != null){
        this.formData.CostMP = parseFloat((this.boardCost.BoardPaperCostMM2 * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100)) * this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000).toFixed(4));
        this.formData.CostMPNeto = this.formData.CostMP;
      if (smOrder <= 1000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr01;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste01 / 100));
      } else if(smOrder <= 2000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr02;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste02 / 100));
      } else if(smOrder <= 3000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr03;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste03 / 100));
      } else if(smOrder <= 4000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr04;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste04 / 100));
      };
      this.formData.ProductionTimeCorr = productionTimeCorr;
        
        //The CostAuxCorrM2 is the cost by production sheetboard and sales sheetboard.
        this.formData.CostAuxCorr = parseFloat(((this.boardCost.StarchCostMM2 + +this.boardCost.CoatingCostMM2 + +this.boardCost.OtherAuxCostMM2) * (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000)).toFixed(4));        
        this.formData.CostCorrToConv = parseFloat(((this.costrateList[0].DirLaborCostHR + +this.costrateList[0].IndLaborCostHR + +this.costrateList[0].MaintenanceCostHR + +this.costrateList[0].EnergyCostoHR + +this.costrateList[0].WaterCostHR + +this.costrateList[0].GasCostHR + +this.costrateList[0].IndManCostHR) * (productionTimeCorr / 60)).toFixed(4));
        this.formData.CostFixed = parseFloat((this.costrateList[0].CorpMiscCostHR * productionTimeCorr / 60).toFixed(4));
        
        //in this block, we are going to calculated the Strech Film cost.
        if (this.formData.StrechFilm == true){
          costFilm = this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatCost * this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatRatCon * (1 + (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatWaste / 100));
          this.formData.CostFilm = parseFloat((costFilm * (this.quoteService.formData.AverageOrder / this.formData.Layer)).toFixed(4));
        }else {
          this.formData.CostFilm = 0;
        };
  
        //in this block, we are gonna calculated the Board for Packaging cost
        costBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatCost * this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatRatCon * (1 + +(this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatWaste / 100));
        this.formData.CostBoardPackaging = parseFloat((costBoardPackaging * (this.quoteService.formData.AverageOrder / this.formData.Layer)).toFixed(4));
        //in this block, we are gonna calculated the Pallet cost
        if (this.formData.Pallet == true){
          costPallet = this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatCost * this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatRatCon * (1 + (this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatWaste / 100));
          this.formData.CostPallet = parseFloat((costPallet * (this.quoteService.formData.AverageOrder / this.formData.Layer)).toFixed(4));
        }else {
          this.formData.CostPallet = 0;
        };
        
        //in this block we are calculated the Tape
        if (this.formData.TapeID != 1){
          costTape = this.auxiliaryList.find(tape => tape.AuxMatID == this.formData.TapeID).AuxMatCost * (1 + (this.auxiliaryList.find(tape => tape.AuxMatID == this.formData.TapeID).AuxMatWaste / 100));
          this.formData.CostTape = parseFloat((costTape * this.formData.LengthBoard * this.quoteService.formData.AverageOrder * this.formData.TapeQuantity / 1000).toFixed(4));
        } else {
          this.formData.CostTape = 0;
          this.formData.TapeQuantity = 0;
        };
             
        //in this block we are going to calculated the Strapping of 5 and 12 mm.
        thickness = this.boardList.find(thicknes => thicknes.BoardID == this.formData.BoardID).Thickness; 
        costStrapping02 = this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatWaste / 100))
        this.formData.CostStrapping12mm = parseFloat(((costStrapping02 / 1000) * (this.quoteService.formData.AverageOrder / this.formData.Layer) * 2 * ((this.formData.Layer * thickness / 1000) + (3 * this.formData.WidthBoard / 1000))).toFixed(4));        
        this.formData.CostWasteConv = 0;
        this.formData.SubTotCorrugator = parseFloat((this.formData.CostMP + +this.formData.CostAuxCorr + +this.formData.CostCorrToConv + +this.formData.CostFixed + +this.formData.CostTape + +this.formData.CostFilm + +this.formData.CostPallet + +this.formData.CostBoardPackaging + +this.formData.CostStrapping12mm + +this.formData.CostWasteConv).toFixed(4));     
        this.formData.SubTotConverting = 0; 
        this.formData.Total = parseFloat((this.formData.SubTotConverting + this.formData.SubTotCorrugator).toFixed(2));
      };
    };
  
    ongetBoardCost(){
      if(this.formData.BoardID != '')
      this.boardcostService.getBoardCostListId(this.formData.BoardID).subscribe((boardCosts: Boardcost) => {
        this.boardCost = boardCosts; this.onBoardCost()}, error => console.error(error));
        ;
    };
  
  //call cost when the value change en checkbox
    ongetCheckChange() {
       this.ongetBoardCost();
    };
  
  //call onBoardCost
    onChangeTape(){
      this.ongetBoardCost();
      if(this.formData.TapeID ==1){
        this.formData.TapeQuantity = 0
      };
    };
  
    onvalidateForm(){
      if (this.formData.BoardID == null){
        this.getformFocus("BoardID", "Resistencia")
      };
      if (this.formData.Width <= 0){
        this.getformFocus("Width", "Ancho")
      };
      if (this.formData.Length <= 0){
        this.getformFocus("Length", "Largo")
      };
    };
  
    getformFocus(formID: string, nameForm: string) {
      this.toastr.error('Por favor, Selecciona un(a) ' + nameForm, 'Cartonero Digital.');
      document.getElementById(formID).focus();
      this.isValid = false;
      return this.isValid;
    };
  
    onLayer(){
      if(this.formData.FluteID == 1){
      this.formData.Layer = 800;
      }else if(this.formData.FluteID == 2){
        this.formData.Layer = 700;
      }else if(this.formData.FluteID == 3){
        this.formData.Layer = 600;
      }else if(this.formData.FluteID == 4){
        this.formData.Layer = 400;
      }else if(this.formData.FluteID == 7){
        this.formData.Layer = 250;
      }else if(this.formData.FluteID == 13){
        this.formData.Layer = 200;
      };
    };
  }
