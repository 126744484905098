<div id="home" class="main-banner main-banner-fade-in-image">
  <div class="container">
<div class="jumbotron jumbotron-fluid" style="text-align: center;">

  <div class="quotehome-banner-content">
  <table style="margin: 0 auto; margin-top: 4%; width: 100%; table-layout: auto; overflow-wrap: break-word;" >
    <tbody>
      <tr>
        <td colspan="3" align="center"><h1><p style="font-size: .64em">Cotizador de Cartón Corrugado</p></h1></td>
      </tr>
      <tr>
          <td colspan="3" align="center">  <!--width="48%" height="48%"-->
            <img src="https://cartonero.net/images/cartonero-digital-255-azules-rojo-match-encode-sans_512x209.png" 
            alt="Cartonero Digital Cotizador de Cajas de Cartón Corrugado"
            title="Cartonero Digital Cotizador de Cajas de Cartón Corrugado"
            style="display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;"/>
            <br><br>
            <button type="button" class="btn btn" style="width: 108px; font-weight: 550; background-color:#2e2d7f ; color: white;" routerLink="/quote" (click)="onQuoteBox()">CAJA</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-outline" style="width: 108px; font-weight: 550; background-color:#2e2d7f ; color: white" routerLink="/quote" (click)="onQuoteSheet()">LÁMINA</button>
          </td>
       </tr>
       <br>
       <tr style="display: block;
          margin-left: auto;
          margin-right: auto;
          width: 50%;">
          <td align="center">
            <dl align="left">
              <dt>&#10011;&nbsp;&nbsp;Beneficios:</dt>
                <dd>&nbsp;&#8226;&nbsp;Cotizar en minutos</dd>
                <dd>&nbsp;&#8226;&nbsp;Cotizaciones en PDF</dd>
                <dd>&nbsp;&#8226;&nbsp;Catálogos Precargados</dd>
                <dd>&nbsp;&#8226;&nbsp;Control de Costos</dd>
                <dd>&nbsp;&#8226;&nbsp;Clientes y Proveedores</dd>
                <dd>&nbsp;&#8226;&nbsp;Certidumbre y Confiabilidad</dd>
            </dl>
          </td>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;&nbsp;</td>
          <td>      
            <dl align="left">
              <dt>&#10003;&nbsp;&nbsp;Ventajas sobre tu competencia:</dt>
                <dd>&nbsp;&#8226;&nbsp;Ganar Mercado al Cotizar rápido</dd>
                <dd>&nbsp;&#8226;&nbsp;Productividad en Ahorro de Tiempo</dd>
                <dd>&nbsp;&#8226;&nbsp;Optimización de tus costos</dd>
                <dd>&nbsp;&#8226;&nbsp;Mayor margen de Ganancia</dd>
                <dd>&nbsp;&#8226;&nbsp;Seleccionar a tu mejor Proveedor</dd>
                <dd>&nbsp;&#8226;&nbsp;Certeza en costo de materia prima</dd>
            </dl>
          </td>
       </tr>
       <tr>
          <td colspan="3">
           <br>
           &#10551;&nbsp;Contáctanos por <A HREF="https://cartonero.net/wa-cotizador">WhatsApp</A> o <a href="mailto:clientes@cartonero.net">Correo Electrónico</a>&nbsp;&#10550;
           <br>
         </td>
       </tr>
      <tr>    
        <td colspan="2">
          <p style="font-size: .66em">
          Las cotizaciones generadas son responsabilidad de quien utilice el Cotizador en Línea<br>
          En Cartonero.Digital estamos atentos a tus comentarios y nos adaptamos a tus necesidades
          </p>
        </td>  
      </tr>
    </tbody>
  </table>
  </div>
  </div>
</div>
</div>