<nav class="navbar navbar-expand-lg navbar-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" 
  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
</button>

  <div class="collapse navbar-collapse menu-back" id="navbarSupportedContent">
    <a class="navbar-brand" href="#">
      <img src="../assets/img/cartonero-digital-256-verde-verde-encode-sans-256x106.png" height="42" alt="">
      </a>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="navbarDropdown" role="button" data-toggle="dropdown" >
          Conoce la Solución 
        </a>
        <ul class="dropdown-menu menu-item-back" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/Cotizador_en_Linea">El Cotizador en línea</a>
          <a class="dropdown-item" href="/Tecnologia_Software_Como_Servicio_SAAS">Tecnología y Servicio SaaS</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/Blog_todo_cartonero">Los Expertos Cartoneros</a>
          <a class="dropdown-item" href="/Diagrama_de_Arquitectura">Diagrama de Seguridad</a>
          
        </ul>
      </li>
      <!-- Segunda opción menú principal "La Empresa"-->
      <li class="nav-item dropdown ">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          La Empresa
        </a>

        <div class="dropdown-menu menu-item-back" aria-labelledby="navbarDropdown">
         
          <a class="dropdown-item" href="/Empresa_Cartonero_Digital">El Pilar de Cartonero Digital</a>
          <a class="dropdown-item" href="/Mercado_Meta">¿El Cotizador es para tí?</a>
          <a class="dropdown-item" href="/Valor_Cartonero_Digital">Te entregamos Valor</a>
          
        </div>
      </li>
      
      <li class="nav-item dropdown active">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Acceso a Cotizador  
        </a>
        <div class="dropdown-menu menu-item-back" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="https://cotizador.cartonero.digital/quote" target="_blank">Ingresar a Cotizar</a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Su Cuenta
        </a>
        <div class="dropdown-menu menu-item-back" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="/landingMail">Abra su Cuenta</a>
            <!--a class="dropdown-item" href="/.auth/logout">Cerrar sesión</a-->
        </div>
      </li>


      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Blog Cartonero 
        </a>
        <div class="dropdown-menu menu-item-back" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/El_Carton_Corrugado">El Cartón Corrugado</a>
          <a class="dropdown-item" href="/Corrugadoras">Corrugadoras</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/Blog_todo_cartonero">Los Expertos Cartoneros</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/videoComponent">Videos</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/">Inicio</a>
      </li>

      <li>
        
    </li>
    </ul>
    
  </div>
 
</nav>

<div class="jumbotron jumbotron-fluid">
  <div class="container" style="max-width: 90%;">
    <div class="row justify-content-end">
      <div class="col-md-12 offset-md-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

