<h4 class="display-5"> Actualizar Costos de Papeles: </h4>
<hr>

<div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="increasePercentaje" id="inlineRadio1" value="option1"
    #increasePercentaje="ngModel" [(ngModel)]="updatePaperCost.increasePercentaje"
    style="width: 20px; height: 20px; vertical-align: middle; text-align: center;" (click)="onOptionPercentaje()">
    <label class="form-check-label" for="inlineRadio1">%</label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name="increasePercentaje" id="inlineRadio2" value="option2"
    #increasePercentaje="ngModel" [(ngModel)]="updatePaperCost.increasePercentaje"
    style="width: 20px; height: 20px; vertical-align: middle; text-align: center;" (click)="onOptionPercentaje()">
    <label class="form-check-label" for="inlineRadio2">$</label>
  </div>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="row">
        <div class="col-md-3">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" name="UpdateTestLiner" id="UpdateTestLiner"
                #UpdateTestLiner="ngModel" [(ngModel)]="updatePaperCost.UpdateTestLiner"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;" (click)="onCheckTestLiner()">
                <label class="form-check-label" for="UpdateTestLiner" style="font-size: large;">Test Liner</label>  
            </div>
        <br>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" name="UpdateTestMedium" id="UpdateTestMedium"
                #UpdateTestMedium="ngModel" [(ngModel)]="updatePaperCost.UpdateTestMedium"
                style="width: 20px; height: 20px; vertical-align: middle; text-align:center;" (click)="onCheckTestLiner()">
                <label class="form-check-label" for="UpdateTestMedium" style="font-size: large;">Test Medium</label>  
            </div>
        <br>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" name="UpdateKraftLiner" id="UpdateKraftLiner"
                #UpdateKraftLiner="ngModel" [(ngModel)]="updatePaperCost.UpdateKraftLiner"
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;" (click)="onCheckTestLiner()">
                <label class="form-check-label" for="UpdateKraftLiner" style="font-size: large;">Kraft Liner</label>  
            </div>
        <br>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" name="UpdateKraftMedium" id="UpdateKraftMedium"
                #UpdateKraftMedium="ngModel" [(ngModel)]="updatePaperCost.UpdateKraftMedium" 
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;" (click)="onCheckTestLiner()">
                <label class="form-check-label" for="UpdateKraftMedium" style="font-size: large;">Kraft Medium</label>  
            </div>
            <br>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" name="UpdateWhiteTop" id="UpdateWhiteTop"
                #UpdateWhiteTop="ngModel" [(ngModel)]="updatePaperCost.UpdateWhiteTop" 
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;" (click)="onCheckTestLiner()">
                <label class="form-check-label" for="UpdateWhiteTop" style="font-size: large;">White Top</label>  
            </div>
            <br>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" name="UpdateAll" id="UpdateAll"
                #UpdateAll="ngModel" [(ngModel)]="updatePaperCost.UpdateAll" 
                style="width: 20px; height: 20px; vertical-align: middle; text-align: center;" (click)="onCheckAllPaper()">
                <label class="form-check-label" for="UpdateAll" style="font-size: large;">Todos</label>  
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <input name="UpdateTestLinerQuantity" id="UpdateTestLinerQuantity" #UpdateTestLinerQuantity="ngModel" 
                [(ngModel)]="updatePaperCost.UpdateTestLinerQuantity" class="form-control" style="height: 28px;" value="0" 
                type="number" min="0">
                <br>
                <input name="UpdateTestMediumQuantity" id="UpdateTestMediumQuantity" #UpdateTestMediumQuantity="ngModel" 
                [(ngModel)]="updatePaperCost.UpdateTestMediumQuantity" class="form-control" style="height: 28px;"
                value="0" type="number" min="0">
                <br>
                <input name="UpdateKraftLinerQuantity" id="UpdateKraftLinerQuantity" #UpdateKraftLinerQuantity="ngModel" 
                [(ngModel)]="updatePaperCost.UpdateKraftLinerQuantity" class="form-control" style="height: 28px;" 
                value="0" type="number" min="0">
                <br>
                <input name="UpdateKraftMediumQuantity" id="UpdateKraftMediumQuantity" #UpdateKraftMediumQuantity="ngModel" 
                [(ngModel)]="updatePaperCost.UpdateKraftMediumQuantity" class="form-control" style="height: 28px;"
                value="0" type="number" min="0">
                <br>
                <input name="UpdateWhiteTopQuantity" id="UpdateWhiteTopQuantity" #UpdateWhiteTopQuantity="ngModel" 
                [(ngModel)]="updatePaperCost.UpdateWhiteTopQuantity" class="form-control" style="height: 28px;"
                value="0" type="number" min="0">
                <br>
                <input name="UpdateAllQuantity" id="UpdateAllQuantity" #UpdateAllQuantity="ngModel" 
                [(ngModel)]="updatePaperCost.UpdateAllQuantity" class="form-control" style="height: 28px;"  
                value="0" type="number" min="0" (keyup)="onvalidateForm()">
            </div>
        </div>
    </div>
    <hr>
    <br>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-dollar"></i> Actualizar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 


