<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>Destino</th>
        <th>Tipo de Unidad</th>
        <th>Costo $</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditFreightItem(null, dataFreight.dataFreight.FreightID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataFreight.dataFreight; let i=index">
            <th (click)="AddOrEditFreightItem(i, item.FreightID)">{{item.FreightID}}</th>
            <td>{{item.Address}}</td>
            <td>{{item.TruckName}}</td>
            <td style="text-align: right;">{{item.FreightCost}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditFreightItem(i, item.FreightID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteFreightItem(item.FreightID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>
