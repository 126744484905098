import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Boardcost } from 'src/app/shared/boardcost.model'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BoardcostService {
  boardCostList: Boardcost;
  dataBoardCost: Boardcost;

  constructor(private http: HttpClient) { }

  getBoardCostList(){
    return this.http.get(environment.apiURL + '/BoardCost').toPromise();
  };

  //getBoardCostListId(boardID: string){
  //  return this.http.get(environment.apiURL + '/BoardCost/'+ boardID).toPromise();
  //}

  //getBoardCostListId(boardID: string): Observable<Boardcost[]>{
  //  return this.http.get<Boardcost[]>(environment.apiURL + '/BoardCost/' + boardID);
  //};

  getBoardCostListId(boardID: string): Observable<Boardcost>{
    return this.http.get<Boardcost>(environment.apiURL + '/BoardCost/' + boardID);
  };

  putBoardCostID(id: string, boardCost: Boardcost) {
    return this.http.put(environment.apiURL + '/BoardCost/' + id, boardCost);
  };

  /* postBoardCost() {
    return this.http.post(environment.apiURL + '/BoardCost', this.boardCostList);
  }; */
}
