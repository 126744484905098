import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ink } from 'src/app/shared/ink.model';
import { InkService } from 'src/app/shared/ink.service';
import { InkItemsComponent } from './ink-items.component';

@Component({
  selector: 'app-ink',
  templateUrl: './ink.component.html',
  styles: []
})
export class InkComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataInk: InkService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

    ngOnInit(): void {
      this.dataInk.getInkList().then(res => this.dataInk.dataInk = res as Ink);
    }
  
    refreshList(){
      //this.dataBoard.dataBoard.BoardIDFilter = "";
      document.getElementById("InkIDFilter").focus();
      var formInkID = <HTMLInputElement> document.getElementById("InkIDFilter");
      //formBoardID.value = ''
      if ( formInkID.value == '' || formInkID.value == undefined){
        this.dataInk.getInkList().then(res => this.dataInk.dataInk = res as Ink);
      } else {
        this.dataInk.getInkListId(formInkID.value).then(res => this.dataInk.dataInk = res as Ink);
      }
    };
  
    AddOrEditInkItem(inkItemIndex, InkID){
      const dialogConfig = new MatDialogConfig(); 
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.width ="60%";
      dialogConfig.height ="40%";
      dialogConfig.data = { inkItemIndex, InkID };
      this.dialog.open(InkItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
        this.refreshList();
        });
    };
  
    onDeleteInkItem(inkID: number){
      if (confirm('Estas seguro de querer eliminar el registro?')){
        this.dataInk.deleteInk(inkID).then(res => {
          this.refreshList();
          this.toastr.warning('Borrado exitosamente!', 'Cartonero Digital.');
        });
      };
    };
  
    onFilterInkName(auxiliaryName: string){
      this.dataInk.getInkListId(auxiliaryName).then(res => this.dataInk.dataInk = res as Ink);
    };
  
    getformFocus(formID: string) {
      document.getElementById(formID).focus();
      var formInkID = <HTMLInputElement> document.getElementById(formID);
      formInkID.value = ''
    };
  

}
