<h4 class="display-4">Mantenimiento del Costo de Resistencias </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>ID Resistencia</label>
            <input name="BoardID" #BoardID="ngModel" [(ngModel)]="boardCostService.boardCostList.BoardID" class="form-control"
            readonly>  
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Papel MXN/MM2</label>
            <input name="BoardPaperCostMM2" id="BoardPaperCostMM2" #BoardPaperCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.BoardPaperCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Papel MXN/Ton</label>
            <input name="BoardPaperCostTon" id="BoardPaperCostTon" #BoardPaperCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.BoardPaperCostTon" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Adhesivo MXN/MM2</label>
            <input name="StarchCostMM2" id="StarchCostMM2" #StarchCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.StarchCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Adhesivo MXN/Ton</label>
            <input name="StarchCostTon" id="StarchCostTon" #StarchCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.StarchCostTon" 
            class="form-control" type="number" min="0">
        </div>
    </div>  
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Recubrimiento MXN/MM2</label>
            <input name="CoatingCostMM2" id="CoatingCostMM2" #CoatingCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.CoatingCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Recubrimiento MXN/Ton</label>
            <input name="CoatingCostTon" id="CoatingCostTon" #CoatingCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.CoatingCostTon" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Agua MXN/MM2</label>
            <input name="WaterCostMM2" id="WaterCostMM2" #WaterCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.WaterCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Agua MXN/Ton</label>
            <input name="WaterCostTon" id="WaterCostTon" #WaterCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.WaterCostTon" 
            class="form-control" type="number" min="0">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Otros Auxiliares MXN/MM2</label>
            <input name="OtherAuxCostMM2" id="OtherAuxCostMM2" #OtherAuxCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.OtherAuxCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Otros Auxiliares MXN/Ton</label>
            <input name="OtherAuxCostTon" id="OtherAuxCostTon" #OtherAuxCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.OtherAuxCostTon" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Gas Montacargas MXN/MM2</label>
            <input name="ForkliftGasCostMM2" id="ForkliftGasCostMM2" #ForkliftGasCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.ForkliftGasCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>Gas Montacargas MXN/Ton</label>
            <input name="ForkliftGasCostTon" id="ForkliftGasCostTon" #ForkliftGasCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.ForkliftGasCostTon" 
            class="form-control" type="number" min="0">
        </div>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 

