import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Auxiliary } from './auxiliary.model';

@Injectable({
  providedIn: 'root'
})
export class AuxiliaryService {
  auxiliaryList: Auxiliary;
  dataAuxiliary: Auxiliary;
  
  constructor(private http: HttpClient) { }

  getAuxiliaryList(){
    return this.http.get(environment.apiURL + '/Auxiliary').toPromise();
  }

  getAuxiliaryListId(auxiliaryName: string){
    return this.http.get(environment.apiURL + '/Auxiliary/'+ auxiliaryName).toPromise();
  }

  deleteAuxiliary(auxiliaryID: number) {
    return this.http.delete(environment.apiURL + '/Auxiliary/' + auxiliaryID).toPromise();
  };

  putAuxiliaryID(id: number, auxiliary: Auxiliary) {
    return this.http.put(environment.apiURL + '/Auxiliary/' + id, auxiliary);
  };

  postAuxiliary() {
    return this.http.post(environment.apiURL + '/Auxiliary', this.auxiliaryList);
  };
}
