import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Supplier } from './supplier.model';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  supplierList: Supplier;
  dataSupplier: Supplier;

  constructor(private http: HttpClient) { }

  getSupplierList(){
    return this.http.get(environment.apiURL + '/Supplier').toPromise();
  };

  getSupplierID(id: number) {
    return this.http.get(environment.apiURL + '/Supplier/' + id).toPromise();
  };

  getSupplierListId(supplierName: string){
    return this.http.get(environment.apiURL + '/Supplier/'+ supplierName).toPromise();
  }

  putSupplierID(id: number, supplier: Supplier) {
    return this.http.put(environment.apiURL + '/Supplier/' + id, supplier);

  };

  postSupplier() {
    return this.http.post(environment.apiURL + '/Supplier', this.supplierList);
  };

  deleteSupplier(id: number) {
    return this.http.delete(environment.apiURL + '/Supplier/' + id).toPromise();
  };
}
