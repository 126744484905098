<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <h1>Blog &nbsp;&nbsp;◽&nbsp;&nbsp; Seguridad en la Nube. | Diagrama de Arquitectura.</h1>
    </div>
</div>
<br>
<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
             <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-img1.jpg" alt="blog-details"> -->
                        <div class="date">Actualizado: 14 mayo 2024</div>
                    </div>
                    <div class="article-content">
                    <blockquote class="blockquote">
                        <h5>La importancia de la Seguridad en la Nube con Cartonero Digital.</h5>
                    </blockquote>
                        <br>
                        <h4 align="center">🔒Diseñamos con la infraestructura de Azure, la Arquitectura de Seguridad multicapa.</h4>
                        <br>
                        <table class="table table-hover">
                        <!-- <thead class="thead-light"> -->
                            <tr>
                            <td width="66%" colspan="0%">
                                <IMG SRC="../../../../assets/img/Diagrama_de_Arquitectura_Cartonero-Digital-2.png" width="100%" ALT="Diagrma de Arquitectura de Cartonero Digital">
                            </td>
                            <td witdh="34%" colspan="0%" align="left">
                                <h4>Seguridad, Autenticación, Identidad y Acceso.</h4>
                                <br><small>✔️ </small>Contraseña Encriptada.
                                <br><small>✔️ </small>Bloqueo de ataques masivos.
                                <br><small>✔️ </small>Factor Múltiple de Autenticación.
                                <br><small>✔️ </small>FireWall y Filtros desde Internet a la Nube.
                                <br><br>
                                <h4>Protección de Datos Dinámico.</h4>
                                <br><small>✔️ </small>Microsoft Defender Cloud.
                                <br><small>✔️ </small>Cifrado y Encriptado en tiempo real.
                                <br><small>✔️ </small>Certificado SSL en Portal Cartonero Digital.
                                <br><small>✔️ </small>Almacenamiento de datos Encriptado.
                            </td>
                            
                            </tr>
                        <!-- </thead> -->
                        </table>
                        
                        <h2 align="center">Políticas con Estándares de Seguridad y Calidad de Información aplicados para su empresa.</h2>
                        <br>
                        <H4>Disponibilidad: Los datos en Cartonero Digital se mantiene en Bases de Datos que cumplen con el principio de Accesibilidad y Oportunidad.</H4>
                        <p><small>✔️ </small>Accesibilidad: Cartonero Digital cuenta con una interfaz del Cotizador en línea por Internet intuitiva, con acceso a datos efectiva y sencilla de usar.</p>
                        <p><small>✔️ </small>Oportunidad: Su información estará disponible, sin limitaciones de horario con cálculos y procesos rápidos.</p>
                        <br>
                        <H4>Confiabilidad: Los datos de los clientes se almacenan y tienen un tratamiento para otorgar exactitud, consistencia e integridad.</H4>
                        <p><small>✔️ </small>Exactitud: al ser precisos indicando siempre el estado real de la información, sin incertidumbre.</p>
                        <p><small>✔️ </small>Consistencia: la información procesada coincide con los datos proporcionados, son verificables.</p>
                        <p><small>✔️ </small>Integridad los datos: tienen claridad en su formato, cumpliendo con criterios de contenido integral.</p>
                        <br>
                        <H4>Usabilidad: La información en el Cotizador es útil dando valor y Credibilidad al cliente.</H4>
                        <p><small>✔️ </small>Credibilidad de datos al ser parte de estándares de la industria, avalados por expertos del medio Cartonero, teniendo claros rangos de valores conocidos y aceptables, sin perder flexibilidad y control.</p>
                        <br>
                        <H4>Legibilidad: Los datos son precisos y claros siendo comprensibles, satisfacen la necesidad del cliente, por su Descripción, su Contenido y su Clasificación.</H4>
                        <p><small>✔️ </small>Descripción de datos que otorga a cada uno el entendimiento claro de lo que representan.</p>
                        <p><small>✔️ </small>Contenido preciso, sin omisiones, excepciones o faltantes.</p>
                        <p><small>✔️ </small>Clasificación de información con datos relacionados lógicamente, siendo prácticos y legibles.</p>

                        <br>
                        <H4>Disponibilidad y Respaldo: La información basada en los datos en Cartonero Digital cumple con los Estándares Tecnológicos por su tratamiento en Alta Disponibilidad, tiempo de Respuesta y Respaldo. <br><br>
                        El acceso es personal con Contraseña y aplicamos el Doble Factor de Autenticación avanzado.</H4> <br>
                        <p><small>✔️ </small>El Cotizador de Cartonero Digital esta soportado por Tecnología Cloud (nube) en Datacenter Azure (Microsoft.)</p>
                        <p><small>✔️ </small>La Tecnología en la Nube proporciona una Alta Disponibilidad, Adaptabilidad, Escalamiento y mantenimiento. Azure.</p>
                        <p><small>✔️ </small>Almacenamiento ilimitado, Procesamiento Escalable y Flexible, Redundancia y Respaldos en tiempo real.</p>
                            

                        <br><br>
                        <h4>El cartón corrugado de cajas se utiliza en diversos sectores, como el embalaje de alimentos, productos electrónicos, productos farmacéuticos, juguetes y mucho más. Su versatilidad y características lo convierten en una opción preferida para el embalaje y transporte de una amplia gama de productos.</h4>
                        <p></p>
                        <br>
   
                        Consulta otros artículos en nuestro blog:
                        <ul class="category" >
                            <li><a HREF="/Corrugadoras" style="text-decoration:none">CORRUGADORAS</a></li>&nbsp;&nbsp;&nbsp;
                            <li><a routerLink="/blog">VALORES DE CARTONERO DIGITAL</a></li>&nbsp;&nbsp;&nbsp;
                            <li><a routerLink="/blog">MERCADO META</a></li>&nbsp;&nbsp;&nbsp;
                            <li><a routerLink="/blog">SaaS</a></li>
                        </ul>
                    </div>
                </div>
                               
                <!--div class="leave-a-reply">
                    <h4>Dejar un comentario, lo publicaremos aquí</h4>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="Su Nombre">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" placeholder="Su mejor correo electrónico">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Su comentario"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-red">Enviar su comentario</button>
                        </div>
                    </div>
                </div-->
                <br>

            </div>
        </div>
    </div>
</div>

<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>