import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-el-carton-corrugado',
  templateUrl: './blog-El-Carton-Corrugado.html',
  styleUrls: ['./blog-El-Carton-Corrugado.scss']
})
export class El_Carton_Corrugado implements OnInit {
    imgSrc:string

  constructor() { }

  ngOnInit(): void {
  }

}
