import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Ink } from 'src/app/shared/ink.model';
import { InkService } from 'src/app/shared/ink.service';

@Component({
  selector: 'app-ink-items',
  templateUrl: './ink-items.component.html',
  styles: []
})
export class InkItemsComponent implements OnInit {
  auxiliaryList: Ink [];
  isValid: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<InkItemsComponent>,
  public inkService: InkService,
  private toastr: ToastrService,
  private router: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //this.supplierService.getSupplierList().then(res => this.supplierList = res as Supplier[])

    if (this.data.inkItemIndex==null){
      this.inkService.inkList = {
        InkID: 0,
        InkGCMI: "",
        InkName: "",
        Cost: null,
      }
    }else {
        this.inkService.inkList = Object.assign({}, this.inkService.dataInk[this.data.inkItemIndex]);
    };
  }

  onSubmit (form: NgForm){
      if (this.isValid == false){
        this.dialogRef.close();
      }else {
        if (this.validateForm(form.value)){
          //this.auxiliaryService.auxiliaryList.AuxMatID = + this.auxiliaryService.auxiliaryList.AuxMatID;
          if (this.data.inkItemIndex == null)
            this.inkService.postInk().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Cartonero Digital.');
          });
          else
            this.inkService.putInkID(this.inkService.inkList.InkID, this.inkService.inkList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Cartonero Digital.');
            });
          this.dialogRef.close();
        };
        this.isValid = true;
      };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formInk: Ink){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.inkService.inkList.InkName == "" || this.inkService.inkList.InkName == null){
      this.getformFocus("InkName", "Descripción de Tinta")
    };
    if (this.inkService.inkList.InkGCMI == "" || this.inkService.inkList.InkGCMI == null){
      this.getformFocus("InkGCMI", "GCMI...")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el ' + nameForm, 'Cartonero Digital.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

}
