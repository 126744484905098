<table class="table table-hover">
    <thead class="thead-light">
        <th>Cotización</th>
        <th>Cliente</th>
        <th>Símbolo</th>
        <th hidden = "true">Destino</th>
        <th style="text-align: right;">Pedido Promedio (cja)</th>
        <th hidden = "true">Freight Cost</th>
        <th hidden = "true">Costo MXN/MPzas</th>
        <th style="text-align: right;">Precio MXN/MPzas</th>
        <th style="text-align: right;">Margen %</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" routerLink="/quote"><i class="fa fa-plus-square"></i> Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of quoteList">
            <th (click)="openForEdit(item.QuoteID)">{{item.QuoteID}}</th>
            <td>{{item.Customer}}</td>
            <td>{{item.CustomerSKU}}</td>
            <td hidden = "true">{{item.Destiny}}</td>
            <td style="text-align: right;">{{item.AverageOrder}}</td>
            <td hidden = "true">{{item.FreightCost}}</td>
            <td hidden = "true">{{item.CostMBox}}</td>
            <td style="text-align: right;">{{item.PriceMBox}}</td>
            <td style="text-align: right;">{{item.ProfitWithFreight}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="openForEdit(item.QuoteID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onQuoteDelete(item.QuoteID)"><i class="fa fa-trash"></i></a>
                <a class="btn btn-sm btn-light text-white ml-1" (click)="onQuoteRep(item.QuoteID)"><i class="fa fa-file-pdf-o" style="color: red"></i></a>
                <a class="btn btn-sm btn-light text-white ml-1" (click)="onQuoteBOM(item.QuoteID)"><i class="fa fa-list" style="color: darkblue"></i></a>
                <a class="btn btn-sm btn-light text-white ml-1" (click)="onQuoteProfit(item.QuoteID)"><i class="fa fa-bar-chart" style="color: green"></i></a>
            </td>
        </tr>
    </tbody>
</table>
