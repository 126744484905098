import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-Diagrama_de_Arquitectura',
  templateUrl: './blog-Diagrama_de_Arquitectura.html',
  styleUrls: ['./blog-Diagrama_de_Arquitectura.scss']
})
export class Diagrama_de_Arquitectura implements OnInit {
    imgSrc:string

  constructor() { }

  ngOnInit(): void {
  }

}
