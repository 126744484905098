import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../shared/quote.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { QuoterepService } from '../shared/quoterep.service';
import { QuoteprofitService } from '../shared/quoteprofit.service';
import { QuotebomService } from '../shared/quotebom.service';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styles: []
})
export class QuotesComponent implements OnInit {
quoteList;
//isSheet: boolean = true;

  constructor(private service: QuoteService, private router: Router,
    private quoterepService: QuoterepService, private toastr: ToastrService,
    private quoteprofitService: QuoteprofitService, private quotebomService: QuotebomService) { }

  ngOnInit(): void {
    this.refreshList();
  }

  refreshList(){
    this.service.getQuoteList().then(res => this.quoteList = res);
  }

  openForEdit(quoteID: number){
    this.router.navigate(['/quote/edit/' + quoteID]);
  }
  
  onQuoteDelete(id: number){
    if (confirm('Estas seguro de querer borrar el registro??')){
      this.service.deleteQuote(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitoso!', 'Cartonero Digital.');
      });
    };
  };

  onQuoteRep(quoteID: number){
    this.quoterepService.getQuoteRepId(quoteID).subscribe(response=>{
      let fileName = "Quote_" + quoteID;
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  };

  onQuoteBOM(quoteID: number){
    this.quotebomService.getQuoteBOMId(quoteID).subscribe(response=>{
      let fileName = "QuoteBOM_" + quoteID;
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  };

  onQuoteProfit(quoteID: number){
    this.quoteprofitService.getQuoteProfitId(quoteID).subscribe(response=>{
      let fileName = "QuoteProfit_" + quoteID;
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  };
}
