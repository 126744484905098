<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <h1>Cartonero Digital SA de CV &nbsp;&nbsp;◽&nbsp;&nbsp; El Pilar de Cartonero Digital</h1>
    </div>
</div>
<br>
<!-- Blog Details -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
             <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <!-- <img src="assets/img/blog-img1.jpg" alt="blog-details"> -->
                        <div class="date">Actualizado: 14 mayo 2024</div>
                    </div>
                    <div class="article-content">
                    <blockquote class="blockquote">
                        <h5>Cartonero Digital: "Cotizador, Servicios de Software as a Service (SaaS)."</h5>
                    </blockquote>
                    <br>
                        <ul class="category" >
                            <!-- <li><a routerLink="/blog">LOS TRES SOCIOS</a></li>-->
                            <li><a routerLink="/blog">VALORES DE CARTONERO DIGITAL</a></li>
                            <li><a routerLink="/blog">MERCADO META</a></li>
                            <li><a routerLink="/blog">SaaS</a></li>
                        </ul>
                        <h4>El cotizador en Línea de cajas de Cartón Corrugado; Sistema Base de la Empresa.</h4>
                        <p>Tres años de inversión en diseño, tiempo y recursos para el desarrollo del Cotizador nos respaldan. 
                        </p><br><br>
                        <h2>Víctor Enrique Ruíz Cervantes</h2>
                        <label>Director General de Cartonero Digital.</label>
                        <p>◽ Diseñador y creador del Cotizador en Línea.</p>
                        <p>◽Más de 15 años de experiencia con cargos de Director y Gerente en el Sector de Fabricación de Cartón Corrugado.</p>
                        <p>◽Ha optimizado procesos en plantas productivas con enfoque en rentabilidad y cadena de valor.</p>
                        <p>◽Líder enfocado a resultados en incremento de ventas y reducción de desperdicio.</p>
                        <p>◽Amplia experiencia en implementación de Sistemas de Información.</p>
                        <p>◽Responsable de certificaciones ISO9001 – 14001 y Excelencia Operacional.</p>
                        <p><small>✔️ </small>Master en Operaciones - Centrum Católica y Barcelona.</p>
                        <p><small>✔️ </small>Master Business Administration - Tecnológico de Monterrey.</p>
                                       
                        <!--br><br>
                        <h4>José Antonio Villarreal Yáñez</h4>
                        <p>Mas de 34 años en el Medio de Tecnología y Sistemas de Software</p>
                        <p>Experto en Desarrollo, Director de Proyectos, Seguridad y Tecnología Cloud</p>
                        <p>Especializado en Soluciones de Software como Servicio en Cloud y Linux</p>
                        <p>Oficial de Riesgos de Información a Nivel nacional en el Sector Financiero</p>
                        <p>Desarrollador de Plataformas, Portales y Sitios para Nichos Deportivos, e-Commerce, Alimentos e Industrias</p>
                        <p><small>✔️ </small>Ingeniero en Sistemas (UIA) con especializaciones en Seguridad Cibernética</p>
                        <br>
                        <blockquote class="blockquote">
                            <h4>El Valor de Cartonero Digital tiene su Pilar en sus Socios Fundadores</h4>
                        </blockquote>
                        <br-->
                        
                    </div>
                </div>
                <!--
                <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a routerLink="/blog-details"><i class="icofont-double-left"></i> Blog Anterior</a>
                    </div>
                    <div class="controls-right">
                        <a routerLink="/blog-details">Siguiente Blog <i class="icofont-double-right"></i></a>
                    </div>
                </div>
                -->
                <!-- 
                <div class="post-comments">
                    <h4>Comentarios</h4>
                    
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖-
                            <img src="assets/img/user1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h3>Ing. Gabriel Dorantes</h3>
                            <p>"Desde la primer semana que usarmos el Cotizador controlamos los Costos, y el margen de ganancia es real"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                           <img src="assets/img/user2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Lic. Eduardo Rodríguez</h4>
                            <p>"Me registré en el Portal, y me invitaron a una Demo en línea y ahora usamos todas las ventajas con Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment">
                        <div class="comment-img">
                            ➖
                            <img src="assets/img/user3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Sr. Vicente Mireles</h4>
                            <p>"Por 1 mes con Cartonero Digital seguimos utilizando un Excel complejo, y poco después ya todo es con el Cotizador"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            ➖
                            <img src="assets/img/user4.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Ing. Juan Antonio Pérez</h4>
                            <p>"Por años buscamos una solución para cotizar Cajas de Cartón, por fin existe una y la mejor Cartonero Digital"</p>
                            <a routerLink="/blog-details">Comentar</a>
                        </div>
                    </div>
                
                </div>
                -->
               
                <!--div class="leave-a-reply">
                    <h4>Dejar un comentario, lo publicaremos aquí</h4>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" placeholder="Su Nombre">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" placeholder="Su mejor correo electrónico">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Su comentario"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-red">Enviar su comentario</button>
                        </div>
                    </div>
                </div-->
                <br>

            </div>
        </div>
    </div>
</div>

<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>