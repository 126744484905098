import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Workstation } from 'src/app/shared/workstation.model';
import { WorkstationService } from 'src/app/shared/workstation.service';
import { WorkstationItemsComponent } from './workstation-items.component';

@Component({
  selector: 'app-workstation',
  templateUrl: './workstation.component.html',
  styles: []
})
export class WorkstationComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataWorkstation: WorkstationService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataWorkstation.getWorkstationList().then(res => this.dataWorkstation.dataWorkstation = res as Workstation);
  }

  refreshList(){
    this.dataWorkstation.getWorkstationList().then(res => this.dataWorkstation.dataWorkstation = res as Workstation);
  };

  AddOrEditWorkstationItem(workstationItemIndex, WorkstationID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="80%";
    dialogConfig.height ="80%";
    dialogConfig.data = { workstationItemIndex, WorkstationID };
    this.dialog.open(WorkstationItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteWorkstationItem(id: number){
    if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataWorkstation.deleteWorkstation(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Cartonero Digital.');
      });
    };
  };
}
