<!-- Cartonero Digital 2.0 - Mayo 2023
 @Antonio Villarreal antonio.villarreal@cartonero.digital
 Testing t2.0
-->

<!--form (submit)="onSubmit(formulario)" #formulario="ngForm">
  <table>
    <tr>
      <td  style="width: 30%">
        <div class="form-group">
          <label>Nombre y Apellido(s)</label>
          <input type="text"
            class="form-control"
            name="lan_nombre"
            [(ngModel)]= "landing.lan_nombre"
            required
            pattern="^[a-zA-Z ]+$">
        </div>
      </td>
    </tr>
    <tr colspan="2">
      <input type="submit" value="Enviar información 1">
      <button class="btn btn-primary btn-orange">Enviar información</button>
    <tr>
  </table>
</form-->


<br><br>
<div id="theForm"
    style="display:flexbox;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;">
    
    <h3 style="text-align:center; font-size: larger">Iniciemos la Comunicación...</h3><br>
    <h3 style="text-align: center; font-size: medium; font-weight: 400; " colspan="3"  >
      Con tu registro te damos una sesión Demo del Cotizador Grátis*
    </h3>
    <br>
    <form #formulario="ngForm" (submit)="onSubmit(formulario)" 
      style="display: inline-block;
      margin-left: auto;
      margin-right: auto;
      text-align: left;"   
    >
      <table>
      <tr>
      <td  style="width: 30%">
      <div class="form-group">
        <label>Nombre y Apellido(s)</label>
        <input type="text"
              class="form-control"
              name="lan_nombre"
              required
              [(ngModel)]= "landing.lan_nombre"
              #lanNombre="ngModel"
              placeholder="Nombre y Apellidos"
              pattern="^[a-zA-Z ]+$">
              <span class="help-block text-danger" *ngIf="lanNombre.errors?.required && lanNombre.touched">
                El Nombre, es un dato requerido.
              </span>
              <span class="help-block text-danger" *ngIf="lanNombre.errors?.pattern && lanNombre.touched">
                El Nombre y Apellido solo debe contener letras.
              </span>
      </div>
      </td>
      <td  style="width: 2%"></td>
      <td  style="width: 30%">
      <div class="form-group">
        <label>Correo Electrónico</label>
        <input type="email"
              class="form-control"
              name="lan_email"
              [(ngModel)]= "landing.lan_email"
              #lanEmail="ngModel"
              placeholder="email@dominio.com"
              pattern="[a-zA-Z0-9!#$%&'*\/=?^_`\{\|\}~\+\-]([\.]?[a-zA-Z0-9!#$%&'*\/=?^_`\{\|\}~\+\-])+@[a-zA-Z0-9]([^@&%$\/\(\)=?¿!\.,:;]|\d)+[a-zA-Z0-9][\.][a-zA-Z]{2,4}([\.][a-zA-Z]{2})?"
              required>
              <span class="help-block text-danger" *ngIf="lanEmail.errors?.required && lanEmail.touched">
                El Correo Electrónico es un dato requerido
              </span>
              <span class="help-block text-danger" *ngIf="lanEmail.errors?.pattern && lanEmail.touched">
                Formato de correo incorrecto, verifique
              </span>
      </div>
    </td>
    <td style="width: 40%" [rowSpan]="5">
      <IMG SRC="../../assets/img/Landing-Page-Cajas-de-Carton-Corrugado-dic-2023.png" width="80%">
    </td>
    </tr>
    <tr>
    <td>
      <div class="form-group">
        <label>Número Celular</label>
        <input type="text"
              class="form-control"
              name="lan_celular"
              [(ngModel)]= "landing.lan_celular"
              #lanCelular="ngModel"
              placeholder="Número de celular"
              required
              pattern="[+]{0,1}[0-9]{10,16}">
              <span class="help-block text-danger" *ngIf="lanCelular.errors?.required && lanCelular.touched">
                El celular es un dato requerido
              </span>
              <span class="help-block text-danger" *ngIf="lanCelular.errors?.pattern && lanCelular.touched">
                Formato del celular no es correcto, verifique
              </span>
      </div>
    </td>
    <td></td>
      <td>
      <div class="form-group">
        <label>Fecha que quiere le contactemos</label>
        <input type="date"
        class="form-control"
        name="lan_fecha"
        [(ngModel)]= "landing.lan_fecha"
        #landFecha="ngModel"
        required>
        <span class="help-block text-danger" *ngIf="landFecha.errors?.required && landFecha.touched">
          Es necesario capture la fecha deseada de contacto
      </span>
      <span class="help-block text-danger" *ngIf="landFecha.errors?.pattern && landFecha.touched">
          Capture o seleccione una fecha válida por favor
       </span>
      </div>
    </td>
  </tr>
    <tr>
    <td>
      <div class="form-group">
        <label>Lo contactaremos como nos indique</label>
        <select class="form-select"
        class="form-control"
        name="lan_tipo"
        [(ngModel)]= "landing.lan_tipo"
        #landTipo="ngModel"
        placeholder="Seleccione metodo de contacto"
        required>
          <option value="" disabled selected hidden>¿Como desea ser contactado?</option>
          <option>Deseo que me llamen</option>
          <option>Contáctenme por WhatsApp</option>
          <option>Envíenme un eMail</option>
          <option>Yo les llamaré</option>
          <option>Contáctenme por zoom</option>
        </select>
        <span class="help-block text-danger" *ngIf="landTipo.errors?.required && landTipo.touched">
          Seleccione como desea ser contactado
        </span>
        <span class="help-block text-danger" *ngIf="landTipo.errors?.pattern && landTipo.touched">
          Seleccione como desea ser contactado
        </span>
      </div>
    </td>
    <td></td>
    <td>
      <div class="form-group">
        <label>Seleccione su especialización</label>
        <select class="form-select"
        class="form-control"
        name="lan_giro"
        [(ngModel)]= "landing.lan_giro"
        #landGiro="ngModel"
        placeholder="Seleccione su especialización"
        required>
          <option value="" disabled selected hidden>El Giro o Sector de su Empresa</option>
          <option>Fabricante de Cajas de Cartón Corrugado</option>
          <option>Comercializador de Cajas de Cartón</option>
          <option>Comprador final de Cajas de Cartón</option>
          <option>Emprendedor o Empresario</option>
          <option>Solo deseo conocer el sistema Cotizador</option>
        </select>
        <span class="help-block text-danger" *ngIf="landGiro.errors?.required && landGiro.touched">
          Por favor seleccione un Giro o Sector
        </span>
        <span class="help-block text-danger" *ngIf="landGiro.errors?.pattern && landGiro.touched">
          El dato capturado no es válido, seleccione una opción
        </span>
      </div>
    </td>
  </tr>
    <tr colspan="2">
      <button 
          class="btn btn-primary btn-orange"
          
          [disabled]="formulario.invalid">Enviar información</button>
        </tr>
    <tr>
      <td style="text-align: right; font-size: smaller; " colspan="3"  >
        Sus datos son tratados con Total Confidencialidad y no se comparten.<br>*Cartonero y Comercial
      </td>
    </tr>
  </table>
    </form>
  </div>

<a href="https://api.whatsapp.com/send?phone=+5214421049462&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Cartonero%20 Digital%20." class="float" target="_blank">
  <i class="fa fa-whatsapp my-float"></i>
</a>