import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { AuxiliaryItemsComponent } from './auxiliary-items.component';

@Component({
  selector: 'app-auxiliary',
  templateUrl: './auxiliary.component.html',
  styles: []
})
export class AuxiliaryComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataAuxiliary: AuxiliaryService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataAuxiliary.getAuxiliaryList().then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
  }

  refreshList(){
    //this.dataBoard.dataBoard.BoardIDFilter = "";
    document.getElementById("AuxiliaryIDFilter").focus();
    var formAuxiliaryID = <HTMLInputElement> document.getElementById("AuxiliaryIDFilter");
    //formBoardID.value = ''
    if ( formAuxiliaryID.value == '' || formAuxiliaryID.value == undefined){
      this.dataAuxiliary.getAuxiliaryList().then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
    } else {
      this.dataAuxiliary.getAuxiliaryListId(formAuxiliaryID.value).then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
    }
  };

  AddOrEditAuxiliaryItem(auxiliaryItemIndex, AuxiliaryID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="60%";
    dialogConfig.height ="50%";
    dialogConfig.data = { auxiliaryItemIndex, AuxiliaryID };
    this.dialog.open(AuxiliaryItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteAuxiliaryItem(auxiliaryID: number){
    if (confirm('Estas seguro de quere borrar el registro?')){
      this.dataAuxiliary.deleteAuxiliary(auxiliaryID).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Cartonero Digital.');
      });
    };
  };

  onFilterAuxiliaryName(auxiliaryName: string){
    this.dataAuxiliary.getAuxiliaryListId(auxiliaryName).then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
  };

  getformFocus(formID: string) {
    document.getElementById(formID).focus();
    var formAuxiliaryID = <HTMLInputElement> document.getElementById(formID);
    formAuxiliaryID.value = ''
  };

}
