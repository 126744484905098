import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteprofitService {

  constructor(private http: HttpClient) { }

  getQuoteProfitId(QuoteID: number){
    return this.http.get(environment.apiURL + '/ProfitabilityRep/'+ QuoteID, {observe:'response', responseType: 'blob'})
  };
}
