import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-todo-cartonero',
  templateUrl: './blog-todo-cartonero.html',
  styleUrls: ['./blog-todo-cartonero.scss']
})
export class Blog_todo_cartonero implements OnInit {
    imgSrc:string

  constructor() { }

  ngOnInit(): void {
  }
}
