<br/>
<form #form="ngForm" autocomplete="off" *ngIf="service.formData" (submit)="onSubmit(form)">
    <input type="hidden" name="QuoteNumber" #QuoteNumber="ngModel" [(ngModel)]="service.formData.QuoteNumber">
    <input type="hidden" name="FreightCost" #FreightCost="ngModel" [(ngModel)]="service.formData.FreightCost">
    <input type="hidden" name="TruckID" #TruckID="ngModel" [(ngModel)]="service.formData.TruckID">
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>Cotización</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">#</div>
                </div>
                <input name="QuoteID" #QuoteID="ngModel" [(ngModel)]="service.formData.QuoteID" class="form-control" readonly>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Fecha</label>
            <input name="QuoteDate" #QuoteDate="ngModel" [(ngModel)]="service.formData.QuoteDate" class="form-control" readonly>
        </div>   
        
        <div class="form-group col-md-2">
            <label>Nombre de Contacto</label>
                <input name="ContactName" #ContactName="ngModel" [(ngModel)]="service.formData.ContactName" class="form-control" readonly>
        </div>
        <div class="form-group col-md-1">

        </div>
        <div class="form-group col-md-2">
            <label>Comisión Ventas Pedido</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="SaleCommission" #SaleCommission="ngModel" [(ngModel)]="service.formData.SaleCommission" class="form-control" readonly style="color: transparent;">
            </div>
        </div>
        <div class="form-group col-md-2">
            <label style="font-weight: bold;">Margen Final con Flete</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
                <input name="ProfitWithFreight" #ProfitWithFreight="ngModel" [(ngModel)]="service.formData.ProfitWithFreight" class="form-control" 
                style="font-weight: bold;" readonly>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label style="font-weight: bold;">Precio Venta Final Millar/Cajas</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="PriceMBox" #PriceMBox="ngModel" [(ngModel)]="service.formData.PriceMBox" class="form-control"
                (keyup)="updateTotalCost()" type="number" min="1" style="font-weight: bold;">
            </div>
        </div>
    </div>
    <div class="form-row"> 
        <div class="form-group col-md-1">
            <label>ID. Cliente</label>
                <input name="CustomerID" #CustomerID="ngModel" [(ngModel)]="service.formData.CustomerID" class="form-control"
                type="number" min="0" (change)="onContactName()">
        </div>
        <div class="form-group col-md-4">
            <label>Cliente</label>
            <select name="CustomerID" #CustomerID="ngModel" [(ngModel)]="service.formData.CustomerID" class="form-control"
            (change)="onGetCustomerProfit($event.target)" [class.is-invalid]="!isValid && service.formData.CustomerID==0">
                <option value="0">-Select-</option>
                <option *ngFor="let item of customerList" value={{item.CustomerID}}>{{item.CustomerName}}</option>
            </select>
        </div>
        <div class="form-group col-md-1">

        </div>
        <div class="form-group col-md-2">
            <label>Gastos Financieros Pedido</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="FinancialExpense" #FinancialExpense="ngModel" [(ngModel)]="service.formData.FinancialExpense" class="form-control" readonly style="color: transparent;">
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Margen Final sin Flete</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
                <input name="ProfitWithoutFreight" #ProfitWithoutFreight="ngModel" [(ngModel)]="service.formData.ProfitWithoutFreight" class="form-control" readonly>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Precio Venta Millar Metros2</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="PriceMM2" #PriceMM2="ngModel" [(ngModel)]="service.formData.PriceMM2" class="form-control" readonly style="color: transparent;">
            </div>
        </div>
    </div>
    <div class="form-row"> 
        <div class="form-group col-md-3">
            <label>Simbolo Producto Cliente</label>
                <input name="CustomerSKU" #CustomerSKU="ngModel" [(ngModel)]="service.formData.CustomerSKU" class="form-control">
        </div>
        <div class="form-group col-md-2">
            <label style="font-weight: bold;">Cantidad Pedido Promedio</label>
            <div class="input-group">
                <div class="input-group-prepend"> 
                    <input name="AverageOrder" #AverageOrder="ngModel" [(ngModel)]="service.formData.AverageOrder" class="form-control"
                    (focusout)="updateQuoteItem()" type="number" style="font-weight: bold;">
                        <div class="input-group-text">Cajas</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-1">
        </div>
        <div class="form-group col-md-2">
            <label>Costo Producción Pedido</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="ManufacturingCost" #ManufacturingCost="ngModel" [(ngModel)]="service.formData.ManufacturingCost" class="form-control" readonly style="color: transparent;">
            </div>
        </div> 
        <div class="form-group col-md-2">
            <label>Margen Objetivo Cliente</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
                <input name="TargetProfit" #TargetProfit="ngModel" [(ngModel)]="service.formData.TargetProfit" class="form-control" readonly>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Precio Venta por Tonelada</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="PriceTon" #PriceTon="ngModel" [(ngModel)]="service.formData.PriceTon" class="form-control" readonly style="color: transparent;">
            </div>
        </div>    
    </div>
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>Destino</label>
            <select name="DestinyID" #DestinyID="ngModel" [(ngModel)]="service.formData.DestinyID" class="form-control" (change)="updateState($event.target)"
            [class.is-invalid]="!isValid && service.formData.DestinyID==0">
                <option value="0">-Select-</option>
                <option *ngFor="let item of destinyList" value="{{item.DestinyID}}">{{item.City}}</option>
            </select>
        </div>
        <div class="form-group col-md-2">
            <label>Cantidad por Embarque</label>
            <div class="input-group">
                <div class="input-group-prepend"> 
                    <input name="BoxShipment" #BoxShipment="ngModel" [(ngModel)]="service.formData.BoxShipment" class="form-control"
                    (focusout)="updateAreaShipment()" type="number">
                    <div class="input-group-text">Cajas</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-1">

        </div>
        <div class="form-group col-md-2">
            <label>Costo Total del Pedido</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="TotalCost" #TotalCost="ngModel" [(ngModel)]="service.formData.TotalCost" class="form-control" readonly>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Precio Sugerido Con Flete</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$/millar</div>
                </div>
                <input name="PriceWithFreight" #PriceWithFreight="ngModel" [(ngModel)]="service.formData.PriceWithFreight" class="form-control" readonly>
            </div>
        </div> 
        <div class="form-group col-md-2">
            <label>Costo Total Millar de Cajas</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="CostMBox" #CostMBox="ngModel" [(ngModel)]="service.formData.CostMBox" class="form-control" 
                readonly style="color: transparent;">
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>Transporte</label>
            <select name="TruckID" #TruckID="ngModel" [(ngModel)]="service.formData.TruckID" class="form-control" (change)="updateFreight($event.target)"
            [class.is-invalid]="!isValid && service.formData.TruckID==0">
                <option value="0">-Select-</option>
                <option *ngFor="let item of truckTypeList" value="{{item.TruckID}}">{{item.TruckName}}</option>
            </select>
        </div>
        <div class="form-group col-md-2">
            <label>Costo Flete por Embarque</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="FreightCost" #FreightCost="ngModel" [(ngModel)]="service.formData.FreightCost" class="form-control" 
                (keyup)="updateTotalCostFreight()" type="number" min="0">
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Cantidad por Mes</label>
            <div class="input-group">
                <div class="input-group-prepend"> 
                    <input name="MonthlyOrder" #MonthlyOrder="ngModel" [(ngModel)]="service.formData.MonthlyOrder" class="form-control"
                    (focusout)="updateQuoteItem()" type="number">
                    <div class="input-group-text">Cajas</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-1">

        </div>
        <div class="form-group col-md-2">
        </div>
        <div class="form-group col-md-2">
            <label>Precio Sugerido sin Flete</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$/millar</div>
                </div>
                <input name="PriceWithoutFreight" #PriceWithoutFreight="ngModel" [(ngModel)]="service.formData.PriceWithoutFreight" class="form-control" readonly>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Costo Total Millar Metros2</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="CostMM2" #CostMM2="ngModel" [(ngModel)]="service.formData.CostMM2" class="form-control" 
		readonly style="color: transparent;">
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>Días de Crédito</label>
            <select name="RateID" #RateID="ngModel" [(ngModel)]="service.formData.RateID" class="form-control"
            [class.is-invalid]="!isValid && service.formData.RateID==0">
                <option value="0">-Select-</option>
                <option *ngFor="let item of interestrateList" value="{{item.RateID}}">{{item.CreditDay}}</option>
            </select>
        </div>
        <div class="form-group col-md-2">
            <label style="color:transparent"> Pedido Mínimo</label>
            <button type="button" class="btn btn-outline-info" style="height: 56%; width: 100%; font-size:small;" (click)="onQuantityMin()"><i class="fa fa-th"></i> Cantidad Mínima Pedido</button>
        </div>
        <div class="form-group col-md-2">
            <label>Cantidad Toneladas Mes</label>
            <div class="input-group">
                <div class="input-group-prepend"> 
                    <input name="MonthlyOrderTon" #MonthlyOrderTon="ngModel" [(ngModel)]="service.formData.MonthlyOrderTon" class="form-control"
                    (focusout)="updateQuoteItem()" type="number" readonly>
                        <div class="input-group-text">Tons</div>
                </div>
            </div>
        </div>
        
        <div class="form-group col-md-1">

        </div>
        
        
        <div class="form-group col-md-2">

        </div>
        
        
        <div class="form-group col-md-2">
        </div>
        <div class="form-group col-md-2">
            <label>Costo Total por Tonelada</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input name="CostTon" #CostTon="ngModel" [(ngModel)]="service.formData.CostTon" class="form-control" readonly style="color: transparent;">
            </div>
        </div>  
    </div>
    <!--Quote Items table-->
    <table class="table table-borderless">
        <thead class="thead-light">
            <th hidden="true">ID</th>
            <th hidden="true">QuoteID</th>
            <th hidden="true">FluteID</th>
            <th style="width: 15%;">Estilo</th>
            <th>Resistencia</th>
            <th hidden="true">StyleID</th>
            <th hidden="true">ClosureID</th>
            <th hidden="true">Register</th>
            <th hidden="true">TapeID</th>
            <th hidden="true">TapeQuantity</th>
            <th>Ancho</th>
            <th>Largo</th>
            <th>Altura</th>
            <th>Score</th>
            <th>Ancho Lámina</th>
            <th>Largo Lámina</th>
            <th hidden="true">AddTrim</th>
            <th hidden="true">Inks</th>
            <th hidden="true">InkID01</th>
            <th hidden="true">PerInk01</th>
            <th hidden="true">PerPrintingPlate01</th>
            <th hidden="true">InkID02</th>
            <th hidden="true">PerInk02</th>
            <th hidden="true">PerPrintingPlate02</th>
            <th hidden="true">InkID03</th>
            <th hidden="true">PerInk03</th>
            <th hidden="true">PerPrintingPlate03</th>
            <th hidden="true">InkID04</th>
            <th hidden="true">PerInk04</th>
            <th hidden="true">PerPrintingPlate04</th>
            <th hidden="true">InkID05</th>
            <th hidden="true">PerInk05</th>
            <th hidden="true">PerPrintingPlate05</th>
            <th hidden="true">InkID06</th>
            <th hidden="true">PerInk06</th>
            <th hidden="true">PerPrintingPlate06</th>
            <th hidden="true">BoxBundle</th>
            <th hidden="true">StrapDirection</th>
            <th hidden="true">StrapNumber</th>
            <th hidden="true">BundleWidthPallet</th>
            <th hidden="true">BundleAlongPallet</th>
            <th hidden="true">Layer</th>
            <th hidden="true">CostMP</th>
            <th hidden="true">CostAuxCorr</th>
            <th hidden="true">CostCorrToConv</th>
            <th>Co$to Corrugadora</th>
            <th hidden="true">CostMacConverting</th>
            <th hidden="true">CostInk</th>
            <th hidden="true">CostPlate</th>
            <th hidden="true">CostPVA</th>
            <th hidden="true">CostCover</th>
            <th hidden="true">CostTape</th>
            <th hidden="true">CostFilm</th>
            <th hidden="true">CostPallet</th>
            <th hidden="true">CostBoardPackaging</th>
            <th hidden="true">CostStrapping5mm</th>
            <th hidden="true">CostStrapping12mm</th>
            <th hidden="true">CostWasteConv</th>
            <th>Co$to Conversión</th>
            <th>
                <!-- <a class="btn btn-sm btn-success text-white" (click)="AddOrEditQuoteItem(null, service.formData.QuoteID)"><i class="fa fa-plus"></i> Caja</a>
                <a class="btn btn-sm btn-success text-white" (click)="AddOrEditQuoteSheet(null, service.formData.QuoteID)"><i class="fa fa-plus"></i> Lámina</a> -->
                <a class="btn btn-sm text-white" style="width: 100px; font-weight: bold; background-color:#2f2D7E ; color: white;" (click)="AddOrEditQuoteItem(null, service.formData.QuoteID)"><i class="fa fa-archive"></i> &nbsp;Caja</a> &nbsp;  
                <a class="btn btn-sm text-white" style="width: 100px; font-weight: bold; background-color:#2f2D7E ; color: white;" (click)="AddOrEditQuoteSheet(null, service.formData.QuoteID)"><i class="fa fa-th"></i> &nbsp;Lámina</a>
            </th>
        </thead>
        <tbody>
            <tr *ngIf="service.quoteItems.length==0" [class.text-danger]="!isValid && service.quoteItems.length==0">
                <td class="font-italic text-center" colspan="5">
                    No hay item seleccionado para esta Cotización.
                </td>
            </tr>
            <tr *ngFor="let item of service.quoteItems; let i=index;">
                <td hidden="true">{{item.QuoteItemID}}</td>
                <td hidden="true">{{item.QuoteID}}</td>
                <td hidden="true">{{item.FluteID}}</td>
                <td>{{item.StyleName}}</td>
                <td>{{item.BoardID}}</td>
                <td hidden="true">{{item.StyleID}}</td>
                <td hidden="true">{{item.ClosureID}}</td>
                <td hidden="true">{{item.Register}}</td>
                <td hidden="true">{{item.TapeID}}</td>
                <td hidden="true">{{item.TapeQuantity}}</td>
                <td>{{item.Width}}</td>
                <td>{{item.Length}}</td>
                <td>{{item.Depth}}</td>
                <td>{{item.Score}}</td>
                <td>{{item.WidthBoard}}</td>
                <td>{{item.LengthBoard}}</td>
                <td hidden="true">{{item.AddTrim}}</td>
                <td hidden="true">{{item.InkNumber}}</td>
                <td hidden="true">{{item.InkID01}}</td>
                <td hidden="true">{{item.PerInk01}}</td>
                <td hidden="true">{{item.PerPrintingPlate01}}</td>
                <td hidden="true">{{item.InkID02}}</td>
                <td hidden="true">{{item.PerInk02}}</td>
                <td hidden="true">{{item.PerPrintingPlate02}}</td>
                <td hidden="true">{{item.InkID03}}</td>
                <td hidden="true">{{item.PerInk03}}</td>
                <td hidden="true">{{item.PerPrintingPlate03}}</td>
                <td hidden="true">{{item.InkID04}}</td>
                <td hidden="true">{{item.PerInk04}}</td>
                <td hidden="true">{{item.PerPrintingPlate04}}</td>
                <td hidden="true">{{item.InkID05}}</td>
                <td hidden="true">{{item.PerInk05}}</td>
                <td hidden="true">{{item.PerPrintingPlate05}}</td>
                <td hidden="true">{{item.InkID06}}</td>
                <td hidden="true">{{item.PerInk06}}</td>
                <td hidden="true">{{item.PerPrintingPlate06}}</td>
                <td hidden="true">{{item.BoxBundle}}</td>
                <td hidden="true">{{item.StrapDirection}}</td>
                <td hidden="true">{{item.StrapNumber}}</td>
                <td hidden="true">{{item.BundleWidthPallet}}</td>
                <td hidden="true">{{item.BundleAlongPallet}}</td>
                <td hidden="true">{{item.NumberBed}}</td>
                <td hidden="true">{{item.BoxArea}}</td>
                <td hidden="true">{{item.DensityBoard}}</td>
                <td hidden="true">{{item.BoxWeight}}</td>
                <td hidden="true">{{item.AreaShipment}}</td>
                <td hidden="true">{{item.TotalAreaRequired}}</td>
                <td hidden="true">{{item.TotalWeightRequired}}</td>
                <td hidden="true">{{item.CostMP}}</td>
                <td hidden="true">{{item.CostAuxCorr}}</td>
                <td hidden="true">{{item.CostCorrToConv}}</td>
                <td>{{item.SubTotCorrugator}}</td>
                <td hidden="true">{{item.CostMacConverting}}</td>
                <td hidden="true">{{item.CostInk}}</td>
                <td hidden="true">{{item.CostPlate}}</td>
                <td hidden="true">{{item.CostPVA}}</td>
                <td hidden="true">{{item.CostCover}}</td>
                <td hidden="true">{{item.CostStrapping5mm}}</td>
                <td hidden="true">{{item.CostStrapping12mm}}</td>
                <td hidden="true">{{item.CostWasteConv}}</td>
                <td>{{item.SubTotConverting}}</td>
                <td>
                    <a class="btn btn-sm btn-info text-white" (click)="AddOrEditQuoteItem(i, service.formData.QuoteID)"><i class="fa fa-pencil"></i></a>
                    <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteQuoteItem(item.QuoteItemID, i)"><i class="fa fa-trash"></i></a>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="form-row">
        <div class="form-group col-md-4">
            <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
            <a class="btn btn-outline-dark ml-1" routerLink="/quotes"><i class="fa fa-table"></i> Ver Cotizaciones</a>
        </div>
        <div class="form-group col-md-4">
            
        </div>        
        <!-- <div class="form-group col-md-4" style="text-align: right;">
            <a class="btn btn-sm text-white" style="width: 150px; font-weight: bold; background-color:#2f2D7E ; color: white;" (click)="AddOrEditQuoteItem(null, service.formData.QuoteID)"><i class="fa fa-archive"></i> &nbsp; &nbsp;Caja</a> &nbsp; &nbsp;  
            <a class="btn btn-sm text-white" style="width: 150px; font-weight: bold; background-color:#2f2D7E ; color: white;" (click)="AddOrEditQuoteSheet(null, service.formData.QuoteID)"><i class="fa fa-th"></i> &nbsp; &nbsp;Lámina</a>
        </div> -->
    </div>
</form>
