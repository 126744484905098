import { Injectable } from '@angular/core';
import { Quote } from './quote.model';
import { QuoteItem } from './quote-item.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoterepService {

  constructor(private http: HttpClient) { }

  getQuoteRepId(QuoteID: number){
    return this.http.get(environment.apiURL + '/QuoteRep/'+ QuoteID, {observe:'response', responseType: 'blob'})
  };
}
