<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="InkIDFilter" id="InkIDFilter" class="form-control" placeholder="Digita la Tinta a buscar...." 
        (keyup)="onFilterInkName($event.target.value)">
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>GCMI</th>
        <th>Descripción</th>
        <th style="text-align: right;">Costo MXN/Kg</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditInkItem(null, dataInk.dataInk.InkID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataInk.dataInk; let i=index;">
            <th (click)="AddOrEditInkItem(i, item.InkID)">{{item.InkID}}</th>
            <td>{{item.InkGCMI}}</td>
            <td>{{item.InkName}}</td>
            <td style="text-align: right;">{{item.Cost}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditInkItem(i, item.InkID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteInkItem(item.InkID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>


