import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Freight } from 'src/app/shared/freight.model';
import { FreightService } from 'src/app/shared/freight.service';
import { FreightItemsComponent } from './freight-items.component';

@Component({
  selector: 'app-freight',
  templateUrl: './freight.component.html',
  styles: []
})
export class FreightComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataFreight: FreightService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataFreight.getFreightTotalList().then(res => this.dataFreight.dataFreight = res as Freight);
  }

  refreshList(){
    this.dataFreight.getFreightTotalList().then(res => this.dataFreight.dataFreight = res as Freight);
  };

  AddOrEditFreightItem(freightItemIndex, FreightID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height ="40%";
    dialogConfig.data = { freightItemIndex, FreightID };
    this.dialog.open(FreightItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteFreightItem(id: number){
    if (confirm('Estas seguro de querer eliminar el Flete seleccionado?')) {
      this.dataFreight.deleteFreight(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Cartonero Digital.');
      }, () =>{
          this.toastr.error('El Flete seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Cartonero Digital.');
      });
    };
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataFreight.deleteFreight(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Cartonero Digital.');
      });
    }; */
  };


}
