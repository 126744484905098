import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Paper } from 'src/app/shared/paper.model';
import { PaperService } from 'src/app/shared/paper.service';
import { PaperItemsComponent } from './paper-items.component';
import { UpdatepapercostComponent } from './updatepapercost.component';

@Component({
  selector: 'app-paper',
  templateUrl: './paper.component.html',
  styles: []
})
export class PaperComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataPaper: PaperService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.dataPaper.getPaperList().then(res => this.dataPaper.dataPaper = res as Paper);
  }

  refreshList(){
    //this.dataBoard.dataBoard.BoardIDFilter = "";
    document.getElementById("PaperIDFilter").focus();
    var formPaperID = <HTMLInputElement> document.getElementById("PaperIDFilter");
    //formBoardID.value = ''
    if ( formPaperID.value == '' || formPaperID.value == undefined){
      this.dataPaper.getPaperList().then(res => this.dataPaper.dataPaper = res as Paper);
    } else {
      this.dataPaper.getPaperId(formPaperID.value).then(res => this.dataPaper.dataPaper = res as Paper);
    }
  };

  AddOrEditPaperItem(paperItemIndex, PaperID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="80%";
    dialogConfig.height ="80%";
    dialogConfig.data = { paperItemIndex, PaperID };
    this.dialog.open(PaperItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeletePaperItem(paperID: number){
    if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataPaper.deletePaper(paperID).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Cartonero Digital.');
      });
    };
  };

  onFilterPaperNomenclature(paperNomenclature: string){
    this.dataPaper.getPaperId(paperNomenclature).then(res => this.dataPaper.dataPaper = res as Paper);

  };

  getformFocus(formID: string) {
    document.getElementById(formID).focus();
    var formPaperID = <HTMLInputElement> document.getElementById(formID);
    formPaperID.value = ''
  };

  updatePaperCost(){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="50%";
    dialogConfig.height = "75%";
    //dialogConfig.data = { paperItemIndex, PaperID };
    this.dialog.open(UpdatepapercostComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };
}
