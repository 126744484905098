<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="CustomerNameFilter" id="CustomerNameFilter" class="form-control" placeholder="Digite el Cliente a buscar..." 
        (keyup)="onFilterCustomerName($event.target.value)">
    </div>
</div>

<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>RFC</th>
        <th>Razon Social</th>
        <th hidden = "true" >Nombre Comercial</th>
        <th>Contacto</th>
        <th hidden = "true">ContactPosition</th>
        <th hidden = "true">Country</th>
        <th hidden = "true">State</th>
        <th hidden = "true">Address</th>
        <th hidden = "true">ZipCode</th>
        <th style="text-align: center;">Teléfono</th>
        <th>Email</th>
        <th style="text-align: center;">Categoría</th>
        <th style="text-align: center;">Margen %</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditCustomerItem(null, dataCustomer.dataCustomer.CustomerID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataCustomer.dataCustomer; let i=index;">
            <th (click)="AddOrEditCustomerItem(i, item.CustomerID)">{{item.CustomerID}}</th>
            <td>{{item.CustomerRFC}}</td>
            <td>{{item.CustomerName}}</td>
            <td hidden = "true" >{{item.CustomerTradeName}}</td>
            <td>{{item.ContactName}}</td>
            <td hidden = "true">{{item.ContactPosition}}</td>
            <td hidden = "true">{{item.CountryID}}</td>
            <td hidden = "true">{{item.StateID}}</td>
            <td hidden = "true">{{item.Address}}</td>
            <td hidden = "true">{{item.ZipCode}}</td>
            <td style="text-align: center;">{{item.Telephone}}</td>
            <td>{{item.Email}}</td>
            <td style="text-align: center;">{{item.CustomerType}}</td>
            <td style="text-align: center;">{{item.TargetProfitability}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditCustomerItem(i, item.CustomerID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteCustomerItem(item.CustomerID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>
