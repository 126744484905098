import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Country } from './country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient) { }

  getCountryList(){
    return this.http.get(environment.apiURL + '/Country').toPromise();
  }

  getCountryListId(countryID: number){
    return this.http.get(environment.apiURL + '/Country/'+ countryID).toPromise();
  }
}
