import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-empresa-cartonero',
  templateUrl: './empresa-cartonero-digital.html',
  styleUrls: ['./empresa-cartonero-digital.scss']
})
export class Empresa_Cartonero_Digital implements OnInit {
    imgSrc:string

  constructor() { }

  ngOnInit(): void {
  }

}
