import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Board } from './board.model';
import { Observable } from 'rxjs';
//import { promise } from 'selenium-webdriver';
import { Boardcost } from './boardcost.model';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  boardList: Board;
  dataBoard: Board;
  boardCostListUp: Boardcost;

  constructor(private http: HttpClient) { }

  getBoardList(){
    return this.http.get(environment.apiURL + '/Board').toPromise();
  }

  getBoardListOneAlternative(quoteSales: string, alternative: number){
    return this.http.get(environment.apiURL + '/Board/' + quoteSales + '/' + alternative).toPromise();
  }

  /* getBoardListId(boardID: string){
    return this.http.get(environment.apiURL + '/Board/'+ boardID).toPromise();
  } */

  getBoardListId(boardFlute: string, fluteID: number, ect: string, colorPaperExt: string, typeMarket: string, typeStarch: string, coatingTo: string): Observable<any>{
    return this.http.get(environment.apiURL + '/Board/'+ boardFlute + '/' + Number(fluteID) + '/' + ect + '/' + colorPaperExt + '/' + typeMarket + '/' + typeStarch + '/' + coatingTo);
  };

  getBoardId(boardID: string){
    return this.http.get(environment.apiURL + '/Board/'+ boardID).toPromise();
  };

  putBoardID(id: string, board: Board) {
    return this.http.put(environment.apiURL + '/Board/' + id, board);
  };

  putBoardCost(boardID: string, updateBoardCost) {
    return this.http.put(environment.apiURL + '/Board/' + boardID + '/' + updateBoardCost, this.boardCostListUp);
  };

  postBoard() {
    return this.http.post(environment.apiURL + '/Board', this.boardList);
  };

  postBoardCost() {
    this.boardCostListUp= {
      BoardID : this.boardList.BoardID,
      BoardPaperCostMM2: 0, 
      BoardPaperCostTon: 0, 
      StarchCostMM2: 0,  
      StarchCostTon: 0,  
      CoatingCostMM2: 0, 
      CoatingCostTon: 0,
      WaterCostMM2: 0, 
      WaterCostTon: 0, 
      OtherAuxCostMM2: 0, 
      OtherAuxCostTon: 0,  
      ForkliftGasCostMM2: 0,
      ForkliftGasCostTon: 0  
    };

    return this.http.post(environment.apiURL + '/BoardCost', this.boardCostListUp);
  };

  deleteBoard(id: string) {
    return this.http.delete(environment.apiURL + '/Board/' + id).toPromise();
  };

  deleteBoardCost(id: string) {
    return this.http.delete(environment.apiURL + '/BoardCost/' + id).toPromise();
  };
}
