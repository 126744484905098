import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Ink } from './ink.model';

@Injectable({
  providedIn: 'root'
})
export class InkService {
  inkList: Ink;
  dataInk: Ink;

  constructor(private http: HttpClient) { }

  getInkList(){
    return this.http.get(environment.apiURL + '/Ink').toPromise();
  }

  getInkListId(inkName: string){
    return this.http.get(environment.apiURL + '/Ink/'+ inkName).toPromise();
  }

  deleteInk(inkID: number) {
    return this.http.delete(environment.apiURL + '/Ink/' + inkID).toPromise();
  };

  putInkID(id: number, ink: Ink) {
    return this.http.put(environment.apiURL + '/Ink/' + id, ink);
  };

  postInk() {
    return this.http.post(environment.apiURL + '/Ink', this.inkList);
  };
}
