import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuotesComponent } from './quotes/quotes.component';
import { QuoteComponent } from './quotes/quote/quote.component';
import { QuoteItemsComponent } from './quotes/quote-items/quote-items.component';

import { QuoteService } from './shared/quote.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { QuoteSheetComponent } from './quotes/quote-items/quote-sheet.component';
import { NgxMaskModule } from 'ngx-mask';
import { CustomerComponent } from './maintenance/customer/customer.component';
import { CustomerItemsComponent } from './maintenance/customer/customer-items.component'
import { CustomerService } from './shared/customer.service';
import { BoardComponent } from './maintenance/board/board.component';
import { BoardItemsComponent } from './maintenance/board/board-items.component';
import { BoardcostItemsComponent } from './maintenance/board/boardcost-Items.component';
import { PaperComponent } from './maintenance/paper/paper.component';
import { PaperItemsComponent } from './maintenance/paper/paper-items.component';
import { BoardService } from './shared/board.service';
import { BoardcostService } from './shared/boardcost.service';
import { PaperService } from './shared/paper.service';
import { SupplierComponent } from './maintenance/supplier/supplier.component';
import { SupplierItemsComponent } from './maintenance/supplier/supplier-items.component';
import { SupplierService } from './shared/supplier.service';
import { AuxiliaryComponent } from './maintenance/auxiliary/auxiliary.component';
import { AuxiliaryItemsComponent } from './maintenance/auxiliary/auxiliary-items.component';
import { AuxiliaryService } from './shared/auxiliary.service';
import { WorkstationComponent } from './maintenance/workstation/workstation.component';
import { WorkstationItemsComponent } from './maintenance/workstation/workstation-items.component';
import { WorkstationService } from './shared/workstation.service';
import { InkComponent } from './maintenance/ink/ink.component';
import { InkItemsComponent } from './maintenance/ink/ink-items.component';
import { QuotehomeComponent } from './quotehome/quotehome.component';
import { UpdatepapercostComponent } from './maintenance/paper/updatepapercost.component';
import { FreightComponent } from './maintenance/freight/freight.component';
import { FreightItemsComponent } from './maintenance/freight/freight-items.component';
import { FreightService } from './shared/freight.service';
import { DestinyComponent } from './maintenance/destiny/destiny.component';
import { DestinyItemsComponent } from './maintenance/destiny/destiny-items.component';
import { DestinyService } from './shared/destiny.service';
import { QuoterepService } from './shared/quoterep.service';
// Cartonero Digital 2.0
import { LandingEmail } from '../admin/landing-email/landing-email.component';
// import { CarouselModule } from 'ngx-owl-carousel-o';
// PopUp windows
import { ModalComponent } from './components/pages/blog-detail-sas/blog-sas-popup/blog-sas-popup.compnent';
// Videos de Cartonero Digital
import { videoComponent } from './video/video.component';
import { LandingService } from './shared/landing.service';

@NgModule({
  declarations: [
    AppComponent,
    QuotesComponent,
    QuoteComponent,
    QuoteItemsComponent,
    QuoteSheetComponent,
    CustomerComponent,
    CustomerItemsComponent,
    BoardComponent,
    BoardItemsComponent,
    BoardcostItemsComponent,
    PaperComponent,
    PaperItemsComponent,
    SupplierComponent,
    SupplierItemsComponent,
    AuxiliaryComponent,
    AuxiliaryItemsComponent,
    WorkstationComponent,
    WorkstationItemsComponent,
    InkComponent,
    InkItemsComponent,
    QuotehomeComponent,
    UpdatepapercostComponent,
    FreightComponent,
    FreightItemsComponent,
    DestinyComponent,
    DestinyItemsComponent,
    // Cartonero Digital 2.0
    LandingEmail,
    ModalComponent,
    videoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    HttpClientModule,
//    CarouselModule,
    ToastrModule.forRoot(),
        // Cartonero Digital 2.0
    NgxMaskModule.forRoot({dropSpecialCharacters: false}),
    ],
  entryComponents:[QuoteItemsComponent, QuoteSheetComponent, CustomerItemsComponent, BoardItemsComponent, BoardcostItemsComponent, 
    PaperItemsComponent, UpdatepapercostComponent, SupplierItemsComponent, AuxiliaryItemsComponent, WorkstationItemsComponent, FreightItemsComponent, DestinyItemsComponent, LandingEmail],
  providers: [QuoteService, CustomerService, BoardService, BoardcostService, PaperService, SupplierService, AuxiliaryService, WorkstationService, FreightService, DestinyService, QuoterepService, LandingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
