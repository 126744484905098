import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Customer } from './customer.model';
import { ToastrService } from 'ngx-toastr';
//import { error } from 'console';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  customerList: Customer;
  dataCustomer: Customer;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  getCustomerList(){
    return this.http.get(environment.apiURL + '/Customer').toPromise();
  };

  getCustomerListId(customerName: string){
    return this.http.get(environment.apiURL + '/Customer/'+ customerName).toPromise();
  }

  getCustomerID(id: number) {
    return this.http.get(environment.apiURL + '/Customer/' + id).toPromise();
  };

  putCustomerID(id: number, customer: Customer) {
    return this.http.put(environment.apiURL + '/Customer/' + id, customer);

  };

  postCustomer() {
    return this.http.post(environment.apiURL + '/Customer', this.customerList);
  };

  deleteCustomer(id: number) {
    return this.http.delete(environment.apiURL + '/Customer/' + id).toPromise();
  };
};

